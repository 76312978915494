import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphClient } from '@services';
import { DEFAULT_VIEW, ItemDataWithPaging, RefreshAction } from '~/utilities';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import Drilldown from './Drilldown';

const FolderDrilldown = () => {
  const [enableToolbarAction, setEnableToolbarAction] = useState(false);
  const {
    state: { location, refreshStamp, newItems, view, orderBy },
  } = useLocation() as DrilldownState;
  const gcl = useGraphClient();
  const getItems = useCallback(
    async (token?: string): Promise<ItemDataWithPaging> => {
      const stamp = await RefreshAction.markRefreshStamp(location, refreshStamp);
      const folder = await location.getFolder(gcl);
      await folder.onLoading(gcl, stamp); // Initialize required data
      setEnableToolbarAction(true); // We can now show the toolbar actions.

      let folderView = view ?? (await folder.getViews(gcl, false, refreshStamp)).find(v => v.isDefault) ?? DEFAULT_VIEW;
      if (orderBy) folderView = folderView.combineWithOrderBy([orderBy]);
      return await folder.getItemsFromView(gcl, folderView, 50, stamp, token);
    },
    [location, gcl, refreshStamp, view, orderBy]
  );
  return (
    <Drilldown
      key={location.apiIdKey}
      enableToolbarActions={enableToolbarAction}
      newItems={newItems}
      location={location}
      getItems={getItems}
    />
  );
};

export default FolderDrilldown;
