import { debounce } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';

export const useDebouncedEffect = (effect, deps, delay) => {
  const isMounted = useRef(false);

  useEffect(() => {
    let handler;
    if (isMounted.current) {
      handler = setTimeout(() => {
        effect();
      }, delay);
    } else {
      isMounted.current = true;
    }

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export const useMUIDebouncedEffect = (effect, delay) => {
  const debouncedEffect = useMemo(() => debounce(effect, delay), [effect, delay]);

  useEffect(() => {
    debouncedEffect();

    return () => {
      debouncedEffect.clear(); // Clean up on unmount or deps change
    };
  }, [debouncedEffect]);
};
