import { NotificationsAndErrorProvider } from '@storybook';
import React from 'react';
import { Outlet } from 'react-router-dom';
import MultiSelectionModalManager from '~/modules/MultiSelectionModal/MultiSelectionModalManager';
import { UploadProvider } from '../../context/UploadProvider';
import KeyPressPublish from '../KeyPress/KeyPressPublish';

const DrilldownShell = () => {
  return (
    <KeyPressPublish>
      <NotificationsAndErrorProvider>
        <MultiSelectionModalManager />
        <UploadProvider>
          <Outlet />
        </UploadProvider>
      </NotificationsAndErrorProvider>
    </KeyPressPublish>
  );
};

export default DrilldownShell;
