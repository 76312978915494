import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '../Box';
import Drawer from '../Drawer/Drawer';

interface BottomDrawerProps {
  children: ReactNode;
  isOpen: boolean;
}

interface BottomDrawerProps {
  children: ReactNode;
  isOpen: boolean;
}

export const BottomDrawer: React.FC<BottomDrawerProps> = ({ children, isOpen }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        maxHeight: '50vh',
        backgroundColor: theme.palette.backgrounds.overlaidComponentsPrimary,
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.palette.shadow.bottomSheet,
        borderRadius: '10px 10px 0 0',
        display: isOpen ? 'block' : 'none',
        overflowY: 'auto',
        zIndex: 1200,
      }}
    >
      {children}
    </Box>
  );
};

interface SelectItemsModalWrapperProps {
  useDrawer: boolean;
  isOpen: boolean;
  children: ReactNode;
}

const SelectItemsModalWrapper: React.FC<SelectItemsModalWrapperProps> = ({ useDrawer, isOpen, children }) => (
  <>
    {useDrawer ? (
      <Drawer open={isOpen} isCustomContainer>
        {children}
      </Drawer>
    ) : (
      <BottomDrawer isOpen={isOpen}>{children}</BottomDrawer>
    )}
  </>
);

export default SelectItemsModalWrapper;
