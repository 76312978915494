import { SiteMedium, IconProps } from '@storybook';
import { NullableOption, SharepointIds, Site } from '@microsoft/microsoft-graph-types';
import { IODataContentType, IODataWeb } from '@microsoft/sp-odata-types';
import { SiteExpirationWindow, GraphSiteKeys, GValue, GraphClient } from '@services';
import { getSortByName, logError } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { SPCList, SharePointClient, SPCSiteKeys, encodeSpecialCharacters, SPCListPropsKeys } from '../sharePointAPI';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  FetchChildrenProps,
  ItemData,
  ItemDataWithPaging,
  MicrosoftApps,
} from '../itemTypes';
import { SharePointSitesRoot } from '../virtualRoots';
import { SPList } from './SPList';
import {
  FieldCreationParameters,
  FieldCreationPayload,
  generatePropertiesCreation,
  prepareEmailCreationProperties,
} from '../manageProperties/Properties';
import { EmailContentTypeBody } from '../manageProperties/emailHeaderMapping';
import { RefreshAction } from '../actions';

export interface SPSiteProps extends BaseItemProps {
  site: string;
  _isUserManaged: boolean;
  graphRoot?: string;
  siteDescription?: string | null;
  description?: string | null;
  OfficeAppLocated: MicrosoftApps;
  isNew?: boolean;
  timeStamp?: number;
}

const FilteredOutLibraries = new Set<string>([
  'SiteCollectionImages',
  'SiteCollectionDocuments',
  'SiteAssets',
  'FormServerTemplates',
  'WorkflowTasks',
  'SiteNewsfeed',
  'Style_x0020_Library',
  'Teams_x0020_Wiki_x0020_Data',
  'Converted_x0020_Forms',
]);

export class SPSite extends BaseContainer {
  private readonly _rootSite: string;
  readonly site: string;
  readonly siteDescription?: string | null;
  private readonly graphRoot?: string;
  _isUserManaged: boolean;
  readonly OfficeAppLocated: MicrosoftApps;

  static fromSharepointIds(spIds: NullableOption<SharepointIds> | undefined, officeAppLocated: MicrosoftApps) {
    const siteSegments = spIds?.siteUrl?.split('/');
    return new SPSite({
      site: spIds?.siteUrl || '',
      id: spIds?.siteId || '',
      _isUserManaged: false,
      graphRoot: '/sites',
      name: decodeURIComponent(siteSegments?.[siteSegments.length - 1] || ''),
      OfficeAppLocated: officeAppLocated,
    });
  }
  constructor(props: SPSiteProps) {
    super({ ...props, type: 'site' });
    this.site = props.site;
    this._rootSite = new URL(props.site).origin;
    this.graphRoot = props.graphRoot;
    this._isUserManaged = props._isUserManaged;
    this.siteDescription = props.siteDescription;
    this.OfficeAppLocated = props.OfficeAppLocated || MicrosoftApps.SharePoint;
  }

  protected fillJson(res: SPSiteProps): void {
    super.fillJson(res);
    res.site = this.site;
    res.graphRoot = this.graphRoot;
    res._isUserManaged = this.isUserManaged;
    res.description = this.description; // For Manage sites
  }

  get description() {
    return this.siteDescription || this.pathOrDescription?.replace(/^SP/, 'SharePoint');
  }
  override get rootSite(): string {
    return this._rootSite;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated ? this.OfficeAppLocated : strings.lang.nodeNames.sharePoint;
    if (appName === 'OneDrive') {
      return `${appName} (${encodeSpecialCharacters(this.site).split('/personal')[1].split('/')[1].split('_')[0]})`;
    }
    if (this.parent?.name) {
      return `${appName} • ${this.parent?.name}`;
    }
    return `${this.OfficeAppLocated || strings.lang.nodeNames.sharePoint}`;
  }
  get pathOrDescription(): string | null {
    return `${MicrosoftApps.SharePoint}${this.site.replace(`${this.rootSite}`, '') || strings.lang.misc.rootSite}`;
  }

  get serverRelativeUrl(): string {
    return this.site.replace(`${this.rootSite}`, '');
  }

  override get isUserManaged(): boolean {
    return this._isUserManaged;
  }
  override get canRemove(): boolean {
    return this.isUserManaged && !this.isAdvancedSearchItem;
  }
  get canUndoRemove(): boolean {
    return true;
  }

  private async moveOrCopyResource(gcl: GraphClient, endpoint: string, fromDecodedUrl: string, destDecodedUrl: string) {
    const spc = new SharePointClient(gcl, this.rootSite);
    await spc.directapi(`${this.site}/_api/SP.MoveCopyUtil.${endpoint}()`).post({
      srcPath: {
        DecodedUrl: fromDecodedUrl,
      },
      destPath: {
        DecodedUrl: destDecodedUrl,
      },
    });
  }

  async moveFile(gcl: GraphClient, fromDecodedUrl: string, destDecodedUrl: string) {
    await this.moveOrCopyResource(gcl, 'MoveFileByPath', fromDecodedUrl, destDecodedUrl);
  }

  async getContentTypes(gcl: GraphClient, timestamp?: number): Promise<IODataContentType[]> {
    const spc = new SharePointClient(gcl, this.rootSite);
    timestamp = await RefreshAction.markRefreshStamp(this, timestamp);
    const cts: GValue<IODataContentType> = await spc
      .api(`${this.site}/_api/web/contenttypes`)
      .cache(SiteExpirationWindow, timestamp)
      .get();
    return cts.value;
  }

  async createField(spc: SharePointClient, fieldParams: FieldCreationParameters): Promise<string> {
    const url = `${this.site}/_api/web/fields/createFieldAsXml`;
    const requestBody: FieldCreationPayload = generatePropertiesCreation(fieldParams);

    try {
      const res = await spc.api(url).post(requestBody);
      return res.Id;
    } catch (error) {
      logError(`Error creating field ${fieldParams.name}: ${error}`);
      throw error;
    }
  }
  async addFieldToContentType(gcl: GraphClient, contentTypeId: string, fieldInternalName: string, fieldId: string) {
    const requestBody = {
      'sourceColumn@odata.bind': `https://graph.microsoft.com/v1.0/sites/root/columns/${fieldId}`,
    };
    const url = `/sites/${this.id}/contentTypes/${contentTypeId}/columns`;
    try {
      await gcl.api(url).post(requestBody);
    } catch (error) {
      logError(`Error adding field ${fieldInternalName} to content type ${contentTypeId}: ${error}`);
      throw error;
    }
  }

  async createEmailContentType(spc: SharePointClient): Promise<void> {
    const fields = prepareEmailCreationProperties();
    try {
      const fieldsIds: string[] = [];
      for (const fieldParams of fields) {
        fieldsIds.push(await this.createField(spc, fieldParams));
      }
      const siteCSOMEndpoint = `${this.site}/_vti_bin/client.svc/ProcessQuery`;
      await spc
        .api(siteCSOMEndpoint)
        .headers({ 'Content-Type': 'application/xml; odata=verbose; charset=utf-8' })
        .post(`${EmailContentTypeBody}`);
    } catch (e) {
      logError(e);
      throw e;
    }
  }

  async moveFolder(gcl: GraphClient, fromDecodedUrl: string, destDecodedUrl: string) {
    await this.moveOrCopyResource(gcl, 'MoveFolderByPath', fromDecodedUrl, destDecodedUrl);
  }

  override async getAccessUrls(): Promise<AccessUrls | undefined> {
    return { webUrl: this.site };
  }

  private async getGraphSubSites({ gcl, refreshStamp, sortDir }: FetchChildrenProps): Promise<ItemData[]> {
    let res: GValue<Site> = await gcl
      .api(`${this.graphRoot}/${this.apiId}/sites`)
      .cache(SiteExpirationWindow, refreshStamp)
      .select(GraphSiteKeys)
      .orderby(`name${sortDir ? '' : ' desc'}`)
      .get();
    const sitesArray = res.value;
    while (res['@odata.nextLink'] !== undefined) {
      res = await gcl
        .api(res['@odata.nextLink'])
        .cache(SiteExpirationWindow, refreshStamp)
        .select(GraphSiteKeys)
        .orderby(`name${sortDir ? '' : ' desc'}`)
        .get();
      sitesArray.push(...res.value);
    }
    return (
      sitesArray?.map(
        v =>
          new SPSite({
            id: v.id || '',
            site: v.webUrl || '',
            name: v.displayName || '',
            graphRoot: this.graphRoot,
            _isUserManaged: false,
            siteDescription: v.description,
            OfficeAppLocated: this.OfficeAppLocated,
          })
      ) || []
    );
  }

  private async getSPRestSubSites({ gcl, refreshStamp, sortDir }: FetchChildrenProps): Promise<ItemData[]> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const res = await spc
      .api(`${this.site}/_api/web/webs`)
      .cache(SiteExpirationWindow, refreshStamp)
      .select(SPCSiteKeys)
      // .orderby(`Title${sortDir ? '' : ' desc'}`) - causes 500 too many resources error.
      .get();
    return (
      res.value
        ?.map(
          (v: IODataWeb) =>
            new SPSite({
              id: v.Id || '',
              site: v.Url || '',
              name: v.Title || '',
              _isUserManaged: false,
              siteDescription: v.Description,
              OfficeAppLocated: this.OfficeAppLocated,
            })
        )
        .sort(getSortByName(sortDir)) || []
    );
  }

  private extractLibPathFromView(viewUrl: string) {
    const segments = viewUrl.split('/');
    let ind = segments.length - 2;
    if (ind >= 0 && segments[ind] === 'Forms') ind--;
    return ind >= 0 ? `${segments.slice(0, ind + 1).join('/')}` : viewUrl;
  }

  private async getSiteLibraries({ gcl, refreshStamp, sortDir }: FetchChildrenProps): Promise<ItemData[]> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const filter = '(BaseType eq 1) and (BaseTemplate ge 10000) or (BaseTemplate eq 101) or (BaseTemplate eq 700)';
    const res: GValue<SPCList> = await spc
      .api(`${this.site}/_api/web/lists`)
      .cache(SiteExpirationWindow, refreshStamp)
      .filter(filter)
      .select(SPCListPropsKeys)
      .orderby(`Title${sortDir ? '' : ' desc'}`)
      .get();
    return (
      res.value
        .filter(v => !v.Hidden && !FilteredOutLibraries.has(v.EntityTypeName))
        .map(
          v =>
            new SPList({
              id: v['odata.id'],
              name: v.Title,
              serverRelativeUrl: this.extractLibPathFromView(v.DefaultViewUrl),
              site: this,
              OfficeAppLocated: this.OfficeAppLocated,
              sharepointListId: v.Id,
            })
        ) || []
    );
  }

  override async fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const listRes =
      (this.graphRoot && (await this.getGraphSubSites(props))) || (await this.getSPRestSubSites(props)) || [];
    const libs = await this.getSiteLibraries(props);
    return { items: listRes.concat(...libs) };
  }

  override async removeItem() {
    return SharePointSitesRoot.Settings.remove(this.apiIdKey);
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SiteMedium, isColorable: true };
  }
}
