import { createContext } from 'react';

type LicenseContextType = {
  isLicenseEnabled: boolean;
  setUserLicense: () => Promise<void>;
};

export const LicenseContext = createContext<LicenseContextType>({
  isLicenseEnabled: true,
  setUserLicense: async () => {},
});
