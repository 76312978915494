import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface CounterBadgeProps {
  count: number;
}

const CounterBadge: React.FC<CounterBadgeProps> = ({ count }) => {
  const theme = useTheme();
  const badgeWidth = 20 + (count.toString().length - 1) * 8;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.buttons.accentPrimary, // Blue background color
        fontWeight: 'bold',
        minWidth: badgeWidth, // Dynamically set width
        height: 20,
        borderRadius: theme.spacing(1),
      }}
    >
      <Typography variant="H1Bold" sx={{ color: '#FFFFFF' }}>
        {count}
      </Typography>
    </Box>
  );
};

export default CounterBadge;
