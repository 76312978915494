import React, { useState, useRef, useEffect } from 'react';
import {
  ContainerHandlers,
  MenuAction,
  FooterWarningAction,
  FooterQuickAction,
  ViewItem,
  SubscribeMultiSelection,
  MultiSelectionEvent,
} from '@storybook';
import { SelectedItemsAdavncedModal } from '@harmon.ie-ce/storybook/src/components/SelectItemsModal/SelectedItemsAdavncedModal';
import { strings } from '@vendor/languages';
import { BaseAction, FavoriteAction, ItemData, RenderActionByHook } from '~/utilities';
import { useSubscribe } from '~/hooks/useSubscribe';
import { CopyLink } from '~/utilities/actions/CopyLink';
import { EmailWithAttachment } from '~/utilities/actions/EmailWithAttachment';
import { RemoveItemAction } from '~/utilities/actions/RemoveItemAction';
import { officeMode, OfficeMode } from '~/misc';
import { EmailWithLink } from '~/utilities/actions/EmailWithLink';

const getFooterQuickAction = (nodes: ViewItem<ItemData>[]) => {
  const actions =
    officeMode === OfficeMode.Read
      ? [new CopyLink(), ...FavoriteAction.create(), ...RemoveItemAction.create()]
      : [new EmailWithAttachment(), new EmailWithLink()];
  return actions.filter((action: BaseAction) => {
    const isHandled = action.isHandled(nodes);
    if (isHandled) {
      return action;
    }
  });
};

const MultiSelectionModalManager = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ViewItem<ItemData>[]>([]);
  const handlersRef = useRef<ContainerHandlers<ItemData>>();
  useSubscribe(MultiSelectionEvent, ({ handlers, selectedItem }: SubscribeMultiSelection) => {
    handlersRef.current = handlers;
    const SelectedNodes = selectedItem ?? handlers.getSelectedNodes();
    if (SelectedNodes.length > 1) {
      setSelectedItems(SelectedNodes);
      setIsModalVisible(true);
    } else {
      setSelectedItems([]);
      setIsModalVisible(false);
    }
  });
  const onCancel = () => {
    handlersRef.current?.unselectAll();
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (selectedItems.length === 0) setIsModalVisible(false);
  }, [selectedItems.length, handlersRef.current?.getSelectedNodes]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isModalVisible) {
        onCancel();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalVisible]);
  const actions =
    selectedItems.length > 0 && handlersRef.current
      ? getFooterQuickAction(selectedItems).map((a, ind) => ({
          render: (
            <RenderActionByHook
              key={`action-${a.constructor.name}-${ind}`}
              action={a}
              actionVariant={a instanceof RemoveItemAction ? FooterWarningAction : FooterQuickAction}
              trigger="Footer Quick Action"
              nodes={selectedItems}
              handlers={handlersRef.current!} // Using non-null assertion since we check for handlersRef.current earlier
            />
          ),
        }))
      : [];
  const footerActions = (actions as MenuAction[]).map(action => action.render);
  return (
    <>
      {
        <SelectedItemsAdavncedModal
          showModal={isModalVisible}
          title={strings.lang.selectItemsModal.items}
          counter={selectedItems.length}
          onCancel={onCancel}
          footerActions={footerActions}
        />
      }
    </>
  );
};

export default MultiSelectionModalManager;
