import React from 'react';
import { ViewItem, TeamsFlatMedium } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { OpenInOfficeAppAction } from './OpenInAppAction';
import { TrackedEventName, TrackedEventType } from './BaseAction';

export class OpenInTeamsAction extends OpenInOfficeAppAction {
  readonly title = strings.lang.actionToolTip.openInTeamsApp;

  override transformEvent({ data }: TrackedEventType) {
    return {
      eventName: 'OpenInApp' as TrackedEventName,
      data: {
        ...data,
        attribute: 'Teams',
      },
    };
  }
  getIcon() {
    return <TeamsFlatMedium />;
  }
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length == 1 && nodes[0].data.type === 'channel'; // The team's deeplink doesn't work all that well. Enable it only for chanels
  }
}

// TODO : Dont use type for isHandled and create isSingleHandled for isHandled!
