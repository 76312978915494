import { v4 as uuidv4 } from 'uuid';

interface Metadata {
  type: string;
}

interface MetadataParameters {
  __metadata: Metadata;
  SchemaXml: string;
  Options: number;
}

export interface FieldCreationPayload {
  parameters: MetadataParameters;
}

export interface ContentTypeRequestBody {
  __metadata: {
    type: string;
  };
  Name: string;
  Description: string;
  Group: string;
  Id: {
    __metadata: {
      type: string;
    };
    StringValue: string;
  };
  ParentId: {
    __metadata: {
      type: string;
    };
    StringValue: string;
  };
}

export interface FieldCreationParameters {
  id: string;
  name: string;
  displayName: string;
  staticName: string;
  type: string;
  required?: boolean;
  showInEditForm?: boolean;
  showInNewForm?: boolean;
  showInDisplayForm?: boolean;
  showInViewForms?: boolean;
  group?: string;
  unlimitedLengthInDocumentLibrary?: boolean;
}

export interface ContentTypeCreationParameters {
  name: string;
  description: string;
  group: string;
  id: string;
}

export const generatePropertiesCreation = (params: FieldCreationParameters): FieldCreationPayload => {
  const {
    id,
    name,
    displayName,
    staticName,
    type,
    required = false,
    showInEditForm = false,
    showInNewForm = false,
    showInDisplayForm = true,
    showInViewForms = true,
    group = 'New Harmon.ie Email Header Mapping',
    unlimitedLengthInDocumentLibrary = false,
  } = params;

  const schemaXml = `
    <Field 
           ID="${id}" 
           Name="${name}" 
           DisplayName="${displayName}" 
           StaticName="${staticName}" 
           Type="${type}" 
           Required="${required ? 'TRUE' : 'FALSE'}" 
           ShowInEditForm="${showInEditForm ? 'TRUE' : 'FALSE'}" 
           ShowInNewForm="${showInNewForm ? 'TRUE' : 'FALSE'}" 
           ShowInDisplayForm="${showInDisplayForm ? 'TRUE' : 'FALSE'}" 
           ShowInViewForms="${showInViewForms ? 'TRUE' : 'FALSE'}" 
           Group="${group}" 
           UnlimitedLengthInDocumentLibrary="${unlimitedLengthInDocumentLibrary ? 'TRUE' : 'FALSE'}">
    </Field>`;

  return {
    parameters: {
      __metadata: { type: 'SP.XmlSchemaFieldCreationInformation' },
      SchemaXml: schemaXml.replace(/\s\s+/g, ' ').trim(),
      Options: 12,
    } as MetadataParameters,
  } as FieldCreationPayload;
};

export const prepareEmailCreationProperties = (): FieldCreationParameters[] => {
  const fields: Omit<FieldCreationParameters, 'id'>[] = [
    {
      name: 'hrmV2To',
      displayName: 'To',
      staticName: 'hrmV2To',
      type: 'Text',
    },
    {
      name: 'hrmV2From',
      displayName: 'From',
      staticName: 'hrmV2From',
      type: 'Text',
    },
    {
      name: 'hrmV2Subject',
      displayName: 'Subject',
      staticName: 'hrmV2Subject',
      type: 'Text',
    },
    {
      name: 'hrmV2Cc',
      displayName: 'Cc',
      staticName: 'hrmV2Cc',
      type: 'Text',
    },
    {
      name: 'hrmV2HasAttachments',
      displayName: 'HasAttachments',
      staticName: 'hrmV2HasAttachments',
      type: 'Boolean',
    },
    {
      name: 'hrmV2Recieved',
      displayName: 'Received',
      staticName: 'hrmV2Recieved',
      type: 'DateTime',
    },
    {
      name: 'hrmV2ConversationTopic',
      displayName: 'Conversation Topic',
      staticName: 'hrmV2ConversationTopic',
      type: 'Text',
    },
    {
      name: 'hrmV2ConversationIndex',
      displayName: 'Conversation Index',
      staticName: 'hrmV2ConversationIndex',
      type: 'Text',
    },
    {
      name: 'hrmV2MailPreviewData',
      displayName: 'MailPreviewData',
      staticName: 'hrmV2MailPreviewData',
      type: 'Note',
      unlimitedLengthInDocumentLibrary: true,
    },
  ];

  return fields.map(field => ({ ...field, id: uuidv4() }));
};
