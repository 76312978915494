import { styled } from '@mui/material';
import {
  ChannelSmall,
  ErrorSmall,
  FolderSmall,
  GenericSmall,
  LibrarySmall,
  MailSmall,
  PersonSmall,
  SiteSamll,
  TeamSmall,
  XMedium,
} from '@harmon.ie-ce/storybook';

export const CustomIcon = (Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, secondary?: boolean) => {
  return styled(Icon)`
    path {
      fill: ${({ theme }) => (secondary ? theme.palette.texts.secondary : theme.palette.texts.primary)};
    }
  `;
};
export const CustomMailIcon = CustomIcon(MailSmall);
export const CustomSiteIcon = CustomIcon(SiteSamll);
export const CustomTeamIcon = CustomIcon(TeamSmall);
export const CustomFolderIcon = CustomIcon(FolderSmall);
export const CustomChannelIcon = CustomIcon(ChannelSmall);
export const CustomLibraryIcon = CustomIcon(LibrarySmall);
export const CustomPersonIcon = CustomIcon(PersonSmall);
export const CustomGenericIcon = CustomIcon(GenericSmall);
export const CustomRemoveIcon = styled(XMedium)`
  path {
    fill: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
`;
export const CustomErrorIcon = styled(ErrorSmall)`
  path {
    fill: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
`;
