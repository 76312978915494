import {
  Box,
  DeleteMedium,
  FooterWarningAction,
  MenuActionsWarningColor,
  NotificationSuccesseMedium,
  RemoveMedium,
  UndoAction,
  useYesNoDialog,
} from '@storybook';
import { useGraphClient } from '@services';
import { ByType, strings } from '@vendor/languages';
import { useCallback } from 'react';
import { template } from 'lodash';
import React from 'react';
import { getNodesType, ItemContainer, ItemData } from '../itemTypes';
import {
  BaseAction,
  BaseDeletActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { RefreshAction } from './RefreshAction';
import { ItemListChangesType, publishItemListChanged } from './UploadAction';

const useRemoveItemAction: ShortcutActionRendererHookType = ({ nodes, handlers, useSafeCallback, actionVariant }) => {
  const gcl = useGraphClient();
  const isUserManaged = nodes[0].data.isUserManaged;
  const texts = strings.lang.removeItem[getNodesType(nodes)];
  const countArg = { count: nodes.length };
  const formattedTexts: typeof texts = {
    ...texts,
    ...(nodes.length > 1
      ? {
          onStartTitle: template(texts?.onStartTitle)(countArg),
          onSuccessTitle: template(texts?.onSuccessTitle)(countArg),
          errorTitle: template(texts?.errorTitle)(countArg),
        }
      : {}),
  };

  const deleteItems = useSafeCallback(
    async () => {
      let firstRes: UndoAction | void = undefined;
      const toPublishById: ByType<ItemListChangesType> = {};
      for (let i = 0; i < nodes.length; i++) {
        const curNode = nodes[i];
        const nData = curNode.data;
        const res = await nData.removeItem(gcl);
        if (firstRes === undefined) firstRes = res;
        nData.parent && (await RefreshAction.markRefreshStamp(nData.parent, Date.now()));
        const location = handlers?.getParent(curNode)?.data as ItemContainer;
        if (!toPublishById[location.id]) toPublishById[location.id] = { location, deleted: {} };
        toPublishById[location.id]!.deleted![nData.apiIdKey] = nData;
      }
      Object.keys(toPublishById).forEach(key => publishItemListChanged(toPublishById[key]!));
      return firstRes;
    },
    [nodes, gcl, handlers],
    false,
    formattedTexts,
    undefined,
    undefined,
    nodes.length > 1 ? 'footer-indications' : '',
    <Box mt={1}>
      <NotificationSuccesseMedium />
    </Box>
  );
  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    blockOutsideClick: true,
    primaryActionCallback: deleteItems,
    texts: {
      ...strings.lang.deleteDialog,
      ...(nodes.length > 1
        ? {
            dialogTitle: strings.lang.deleteMultipleDialog.dialogTitle({ count: nodes.length }),
          }
        : {}),
    },
  });

  const title = texts?.actionTitle || '';
  const icon = isUserManaged ? RemoveMedium : DeleteMedium;
  const onClick = useCallback(async () => {
    if (isUserManaged) {
      await deleteItems();
    } else {
      setIsDialogVisible(true);
    }
  }, [deleteItems, isUserManaged, setIsDialogVisible]);
  return {
    title,
    icon,
    onClick,
    actionVariant: actionVariant === FooterWarningAction ? actionVariant : MenuActionsWarningColor,
    chipLabel: strings.lang.shortcuts.delete,
    SideUI: YesNoDialog,
  };
};

export class RemoveItemAction extends BaseDeletActionClass implements SelectedItemHandler, ShortcutAction {
  constructor(readonly isUserManaged: boolean) {
    super();
  }
  readonly useRenderer = useRemoveItemAction;
  isHandled = BaseAction.isMultiHandled(this);
  isSingleHandled = (d: ItemData) =>
    d.isUserManaged === this.isUserManaged && d.canRemove && !d.isSearchItem && !d.isAdvancedSearchItem;
  shortcut: [KeyModifiers, string] = [KeyModifiers.None, 'Delete'];

  static create = () => [new RemoveItemAction(true), new RemoveItemAction(false)];
}
