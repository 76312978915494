import React, { useEffect, useState } from 'react';
import {
  FavoriteOutlineSmall,
  FavoriteFillSmall,
  FavoriteMedium,
  FavoriteFillMedium,
  filledFavoriteAnimationData,
  emptyFavoriteAnimationData,
  LottieAnimation,
  itemFastActions,
  favoriteQuickActions,
} from '@storybook';
import { strings } from '@vendor/languages';
import {
  ActionItemType,
  ActionRendererHookType,
  BaseAction,
  BaseAddActionClass,
  SelectedItemHandler,
  TrackedEventName,
  TrackedEventType,
} from './BaseAction';
import { FavoritesRoot } from '../virtualRoots';
import { ItemData } from '../itemTypes';

const useFavoriteAction: ActionRendererHookType = ({ action, nodes: nodes, actionVariant, useSafeCallback }) => {
  const allFavs = FavoritesRoot.AllFavorites;
  const isFavorite = (action as FavoriteAction).isFavorite;
  const [isSelected, setIsSelected] = useState(isFavorite);
  const [playAnimation, setPlayAnimation] = useState(false);

  const onClick = useSafeCallback(
    async () => {
      //const keepFavs = new Map(allFavps.value);
      nodes.map(node => {
        const data = node.data as ItemData;
        const isFavorite = allFavs.isFavorite(data);
        if (isFavorite) allFavs.remove(data.apiIdKey);
        else {
          allFavs.add(data, Date.now());
          FavoritesRoot.AddNewItem(data);
        }
        setIsSelected(!isFavorite);
        setPlayAnimation(true);
      });
      if (isSelected) {
        return {
          run: () => {
            nodes.map(node => {
              const data = node.data as ItemData;
              allFavs.add(data, data.timeStamp);
            });
          },
        };
      }
    },
    [allFavs, isSelected, nodes],
    false,
    isSelected ? strings.lang.notifications.renovedFromFavorite : strings.lang.notifications.addToFavorite,
    undefined,
    undefined,
    nodes.length > 1 ? 'footer-indications' : ''
  );

  useEffect(() => {
    setIsSelected(isFavorite);
  }, [isFavorite]);
  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 50); // Adjust the timeout duration to match the duration of your animation
    }
  }, [playAnimation]);

  if (isSelected && actionVariant === itemFastActions) {
    actionVariant = favoriteQuickActions;
  }
  const fill = actionVariant.withText ? {} : { 'data-nofill': true };

  return {
    title: isFavorite ? strings.lang.actionToolTip.removefavorite : strings.lang.actionToolTip.addfavorite,
    icon: playAnimation ? (
      <LottieAnimation
        options={{
          loop: false,
          autoplay: true,
          animationData: isSelected ? filledFavoriteAnimationData : emptyFavoriteAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={16}
        width={16}
      />
    ) : isSelected ? (
      actionVariant.withText || actionVariant.useMediumIcon ? (
        <FavoriteFillMedium {...fill} />
      ) : (
        <FavoriteFillSmall />
      )
    ) : actionVariant.withText || actionVariant.useMediumIcon ? (
      <FavoriteMedium {...fill} />
    ) : (
      <FavoriteOutlineSmall />
    ),
    onClick,
    actionVariant: actionVariant,
  };
};

export class FavoriteAction extends BaseAddActionClass implements SelectedItemHandler {
  constructor(readonly isFavorite: boolean) {
    super();
  }

  readonly useRenderer = useFavoriteAction;
  transformEvent({ data }: TrackedEventType) {
    return {
      data: {
        ...data,
        itemType: 'Favorite' as ActionItemType,
      },
      eventName: (this.isFavorite ? 'DeleteItems' : 'AddItems') as TrackedEventName,
    };
  }

  isQuickAction(_data: ItemData, isTopBarItem?: boolean) {
    if (isTopBarItem) return undefined;
    return 'only';
  }

  // To Favorite items we require that at least one item isn't a favorite. To Unfavorite, all items must be favorites
  isHandled = (this.isFavorite ? BaseAction.isMultiHandled : BaseAction.isAnyHandled)(this);
  isSingleHandled = (d: ItemData) =>
    !d.isVirtual &&
    !d.isUserManaged &&
    !d.isRootSection &&
    this.isFavorite === FavoritesRoot.AllFavorites.isFavorite(d);

  static create = () => [new FavoriteAction(true), new FavoriteAction(false)];
}
