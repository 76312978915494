import React from 'react';
import { Stack } from '@mui/system';
import { Button } from '../Button';
import { MenuAction } from '../ContextMenu';
import { IndicatorStatus } from './Indicator';

export interface IndicatorFooterProps {
  isCanceling: boolean | undefined;
  onCancel: (() => void) | undefined;
  cancel: string;
  hasCancel: boolean;
  status: IndicatorStatus;
  indicatorActions?: MenuAction[];
}

export const IndicatorFooter = ({
  cancel,
  isCanceling,
  onCancel,
  hasCancel,
  status,
  indicatorActions,
}: IndicatorFooterProps) => {
  const hasFooter =
    (hasCancel && status === 'progress') || (status === 'success' && indicatorActions && indicatorActions?.length > 0);
  return (
    <Stack width={'100%'} justifyContent="flex-end" direction="row" minHeight={hasFooter ? 32 : 0}>
      {status === 'success' && indicatorActions && (
        <Stack direction={'row'} spacing={2}>
          {indicatorActions.map(action => action.render)}
        </Stack>
      )}
      {hasCancel && status === 'progress' && (
        <Button disabled={isCanceling} variant="text" onClick={onCancel} color="secondary">
          {cancel}
        </Button>
      )}
    </Stack>
  );
};
