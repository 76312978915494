import { AttachmentMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { useContext } from 'react';
import { OfficeMode, officeMode } from '~/misc';
import IndicatorContext from '~/context/IndicatorContext';
import { ItemData } from '../itemTypes';
import {
  BaseAction,
  BaseReadActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { emailWithAttachment } from './ActionsUtils';
import { isMacOsNative } from '../misc';

const useEmailWithAttachment: ShortcutActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const {
    errorfulProcessIndication,
    oneItemCompletedIndication,
    oneItemStartIndication,
    progressRequestHandler,
    startProgressIndication,
    successfulProcessIndication,
    applyClassName,
  } = useContext(IndicatorContext);
  const onClick = useSafeCallback(
    async () => {
      if (nodes.length > 1 && applyClassName) applyClassName('footer-indications');
      startProgressIndication?.(
        nodes.length,
        strings.lang.addAttachmentsIndicatorTexts,
        nodes.map(node => node.data.name)
      );
      for (let i = 0; i < nodes.length; i++) {
        if (progressRequestHandler?.needToCancel()) {
          return;
        }
        if (progressRequestHandler?.getCurrentProgressIndex())
          progressRequestHandler.getCurrentProgressIndex().current = i;
        oneItemStartIndication?.(i);
        await emailWithAttachment(gcl, nodes[i].data as ItemData);
        oneItemCompletedIndication?.(i);
      }
      successfulProcessIndication?.();
    },
    [
      applyClassName,
      gcl,
      nodes,
      oneItemCompletedIndication,
      oneItemStartIndication,
      progressRequestHandler,
      startProgressIndication,
      successfulProcessIndication,
    ],
    false,
    {},
    undefined,
    errorfulProcessIndication,
    nodes.length > 1 ? 'footer-indications' : ''
  );
  return {
    title: nodes.length == 1 ? strings.lang.actionToolTip.addAttachment : strings.lang.actionToolTip.addAttachments,
    icon: AttachmentMedium,
    onClick,
    chipLabel: isMacOsNative() ? strings.lang.shortcuts.addAttachmentMac : strings.lang.shortcuts.addAttachment,
  };
};

export class EmailWithAttachment extends BaseReadActionClass implements SelectedItemHandler, ShortcutAction {
  readonly trackedName = 'EmailWithAttachment';
  readonly useRenderer = useEmailWithAttachment;
  isHandled = BaseAction.isMultiHandled(this);

  isSingleHandled = (d: ItemData) => officeMode !== OfficeMode.Read && d.canOpenInBrowser && d.isDocument;

  shortcut: [KeyModifiers, string] = [KeyModifiers.Alt, isMacOsNative() ? 'å' : 'a'];
}
