import React from 'react';
import { useTheme } from '@mui/material';
import { strings } from '@vendor/languages';
import { XMedium } from '../../assets/icons';
import { Stack } from '../Stack';
import { Button } from '../Button';
import SelectItemsModalWrapper from './SelectItemsModalWrapper';
import { Typography } from '../Typography';
import CounterBadge from './CounterBadge';
import { Tooltip } from '../Tooltip';
import { Divider } from '../Divider';

interface SelectItemsModalProps {
  showModal: boolean;
  title: string;
  counter: number;
  onCancel?: () => void;
  footerActions: (React.JSX.Element | undefined)[];
}

export const SelectedItemsAdavncedModal: React.FC<SelectItemsModalProps> = ({
  onCancel,
  showModal,
  title,
  counter,
  footerActions,
}) => {
  const theme = useTheme();
  const cancel = () => {
    onCancel && onCancel();
  };
  return (
    <SelectItemsModalWrapper isOpen={showModal} useDrawer={false}>
      <Stack
        sx={{
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          background: theme.palette.backgrounds.overlaidComponentsPrimary,
          paddingX: 1,
          paddingY: 2,
        }}
      >
        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            marginLeft={`${theme.spacing(2)} !important`}
          >
            <CounterBadge count={counter} />
            <Typography variant="CapsLockSemiBold" color={theme.palette.texts.quaternary}>
              {title}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={2} justifyContent="space-between" alignItems="center">
            {footerActions}
            <Divider sx={{ height: '24px', color: theme.palette.outlinesAndStrokes.primary }} orientation="vertical" />
            <Tooltip title={strings.lang.actions.cancel}>
              <Button variant="IconButtons" onClick={cancel}>
                <XMedium />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </SelectItemsModalWrapper>
  );
};
