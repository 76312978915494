// harmonie icons
export { ReactComponent as AddSmall } from './small/harmonie/Add.svg';
export { ReactComponent as CaretClosedSmall } from './small/harmonie/Caret closed.svg';
export { ReactComponent as SettingsSmall } from './small/harmonie/Settings.svg';
export { ReactComponent as ShareSmall } from './small/harmonie/Share.svg';
export { ReactComponent as SaveEmailSmall } from './small/harmonie/SaveEmail.svg';

export { ReactComponent as CollapseSmall } from './small/harmonie/Collapse.svg';
export { ReactComponent as VSmall } from './small/harmonie/V.svg';
export { ReactComponent as XSmall } from './small/harmonie/X.svg';
export { ReactComponent as XSmallest } from './small/harmonie/XSmallest.svg';
export { ReactComponent as CalendarSmall } from './small/harmonie/Calendar.svg';
export { ReactComponent as CaretOpenedSmall } from './small/harmonie/CaretOpened.svg';
export { ReactComponent as DownloadSmall } from './small/harmonie/Download.svg';
export { ReactComponent as GroupTeamsSmall } from './small/harmonie/GroupTeams.svg';
export { ReactComponent as ExclamationMarkSmall } from './small/harmonie/ExclamationMark.svg';
export { ReactComponent as FavoriteFillSmall } from './small/harmonie/FavoriteFill.svg';
export { ReactComponent as FavoriteOutlineSmall } from './small/harmonie/FavoriteOutline.svg';
export { ReactComponent as InformationSmall } from './small/harmonie/Information.svg';
export { ReactComponent as MenuSmall } from './small/harmonie/Menu.svg';
export { ReactComponent as CheckMarkSmall } from './small/harmonie/Checkmark.svg';
export { ReactComponent as OpenInBrowserSmall } from './small/harmonie/OpenInBrowser.svg';
export { ReactComponent as RefreshSmall } from './small/harmonie/Refresh.svg';
export { ReactComponent as ArrowSmall } from './small/harmonie/Arrow.svg';
export { ReactComponent as AttachmentSmall } from './small/harmonie/Attachment.svg';
export { ReactComponent as ForwardSmall } from './small/harmonie/Forward.svg';
export { ReactComponent as MailSmall } from './small/harmonie/Mail.svg';
export { ReactComponent as DeletelSmall } from './small/harmonie/Delete.svg';
export { ReactComponent as AToZlSmall } from './small/harmonie/A to Z.svg';
export { ReactComponent as CheckSmall } from './small/harmonie/Check.svg';
export { ReactComponent as UploadSmall } from './small/harmonie/Upload.svg';
export { ReactComponent as ErrorSmall } from './small/harmonie/Error.svg';
export { ReactComponent as EllipseSelected } from './small/harmonie/EllipseSelected.svg';
export { ReactComponent as NewFolderSmall } from './small/harmonie/New folder.svg';
export { ReactComponent as LocationSmall } from './small/harmonie/Location.svg';
export { ReactComponent as FolderSmall } from './small/harmonie/Folder.svg';
export { ReactComponent as LibrarySmall } from './small/harmonie/SP document library.svg';
export { ReactComponent as PersonSmall } from './small/harmonie/User.svg';
export { ReactComponent as GenericSmall } from './small/harmonie/Generic file.svg';
export { ReactComponent as ArrowUpSmall } from './small/harmonie/Arrow up.svg';
export { ReactComponent as ArrowDownSmall } from './small/harmonie/Arrow down.svg';
export { ReactComponent as LinkSmall } from './small/harmonie/Link.svg';
export { ReactComponent as CheckBoxSmall } from './small/harmonie/CheckBox.svg';
export { ReactComponent as CheckBoxFillSmall } from './small/harmonie/CheckBoxFill.svg';
export { ReactComponent as CheckBoxEmptySmall } from './small/harmonie/CheckBoxEmpty.svg';
export { ReactComponent as CheckBoxMixedSmall } from './small/harmonie/CheckboxMixed.svg';
export { ReactComponent as RemoveSmall } from './small/harmonie/Remove.svg';
export { ReactComponent as StarSmall } from './small/harmonie/Star.svg';
export { ReactComponent as UnionSmall } from './small/harmonie/Union.svg';

// microsoft icons
export { ReactComponent as TeamsFlatSmall } from './small/microsoft/Teams flat.svg';
export { ReactComponent as PepoleTeamSmall } from './small/microsoft/People team.svg';
export { ReactComponent as DocxSamll } from './small/microsoft/docx.svg';
export { ReactComponent as MeetingNotesSamll } from './small/microsoft/loop.svg';
export { ReactComponent as PdfSamll } from './small/microsoft/pdf.svg';
export { ReactComponent as PptxSamll } from './small/microsoft/pptx.svg';
export { ReactComponent as XlsxSamll } from './small/microsoft/xlsx.svg';
export { ReactComponent as OneDriveSamll } from './small/microsoft/OneDrive.svg';
export { ReactComponent as SharePointSamll } from './small/microsoft/SharePoint.svg';
export { ReactComponent as TeamsSamll } from './small/microsoft/Teams.svg';
export { ReactComponent as SiteSamll } from './small/microsoft/Site.svg';
export { ReactComponent as TeamSmall } from './small/microsoft/People team.svg';
export { ReactComponent as ChannelSmall } from './small/microsoft/Channel.svg';
export { ReactComponent as AvatarPersonSmall } from './small/microsoft/AvatarPerson.svg';
export { ReactComponent as MicrosoftLogoSmall } from './small/microsoft/Microsoft_logo.svg';

//---------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------Medium---------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------------------

// harmonie icons
export { ReactComponent as SupportIconMedium } from './medium/harmonie/Support.svg';
export { ReactComponent as RadioSelected } from './medium/harmonie/RadioSelected.svg';
export { ReactComponent as RadioNotSelected } from './medium/harmonie/RadioNotSelected.svg';
export { ReactComponent as Line } from './medium/harmonie/Line.svg';
export { ReactComponent as SharedWithMeMedium } from './medium/harmonie/SharedWithMe.svg';
export { ReactComponent as AttachmentMedium } from './medium/harmonie/Attachment.svg';
export { ReactComponent as ShareLinkMedium } from './medium/harmonie/ShareLink.svg';
export { ReactComponent as BackMedium } from './medium/harmonie/Back.svg';
export { ReactComponent as DeleteMedium } from './medium/harmonie/Delete.svg';
export { ReactComponent as LogoutMedium } from './medium/harmonie/Logout.svg';
export { ReactComponent as ResethMedium } from './medium/harmonie/Reset.svg';
export { ReactComponent as DocLibraryMedium } from './medium/harmonie/SP document library.svg';
export { ReactComponent as DownloadMedium } from './medium/harmonie/Download.svg';
export { ReactComponent as FavoriteMedium } from './medium/harmonie/Favorite.svg';
export { ReactComponent as ViewsActionMedium } from './medium/harmonie/ViewsAction.svg';
export { ReactComponent as AdvancedFiltersMedium } from './medium/harmonie/AdvancedFilters.svg';
export { ReactComponent as FolderClosedMedium } from './medium/harmonie/FolderClosed.svg';
export { ReactComponent as FolderOpenedMedium } from './medium/harmonie/FolderOpened.svg';
export { ReactComponent as OpenInBrowserMedium } from './medium/harmonie/OpenInBrowser.svg';
export { ReactComponent as CalendarMedium } from './medium/harmonie/Calendar.svg';
export { ReactComponent as FormMedium } from './medium/harmonie/Form.svg';
export { ReactComponent as HomeMedium } from './medium/harmonie/Home.svg';
export { ReactComponent as GroupTeamsMedium } from './medium/harmonie/GroupTeams.svg';
export { ReactComponent as RecentMedium } from './medium/harmonie/Recent.svg';
export { ReactComponent as RefreshMedium } from './medium/harmonie/Refresh.svg';
export { ReactComponent as RenameMedium } from './medium/harmonie/Rename.svg';
export { ReactComponent as SearchMedium } from './medium/harmonie/Search.svg';
export { ReactComponent as SettingsMedium } from './medium/harmonie/Settings.svg';
export { ReactComponent as ShareMedium } from './medium/harmonie/Share.svg';
export { ReactComponent as LinkMedium } from './medium/harmonie/Link.svg';
export { ReactComponent as SortMedium } from './medium/harmonie/Sort.svg';
export { ReactComponent as SwayMedium } from './medium/harmonie/Sway.svg';
export { ReactComponent as TrendingMedium } from './medium/harmonie/Trending.svg';
export { ReactComponent as UploadMedium } from './medium/harmonie/Upload.svg';
export { ReactComponent as SaveEmailMedium } from './medium/harmonie/SaveEmail.svg';
export { ReactComponent as ZipMedium } from './medium/harmonie/Zip.svg';
export { ReactComponent as PropertiesMedium } from './medium/harmonie/Properties.svg';
export { ReactComponent as MailLinkMedium } from './medium/harmonie/MailLink.svg';
export { ReactComponent as MailMedium } from './medium/harmonie/Mail.svg';
export { ReactComponent as MultipleMedium } from './medium/harmonie/Multiple.svg';
export { ReactComponent as NewFolderMedium } from './medium/harmonie/NewFolder.svg';
export { ReactComponent as ArrowDownMedium } from './medium/harmonie/Arrow down.svg';
export { ReactComponent as ArrowUpMedium } from './medium/harmonie/Arrow up.svg';
export { ReactComponent as ArrowLeftMedium } from './medium/harmonie/Arrow left.svg';
export { ReactComponent as ArrowRightMedium } from './medium/harmonie/Arrow right.svg';
export { ReactComponent as VMedium } from './medium/harmonie/V.svg';
export { ReactComponent as VideoMedium } from './medium/harmonie/Video.svg';
export { ReactComponent as URLLinkMedium } from './medium/harmonie/URL link.svg';
export { ReactComponent as AddMedium } from './medium/harmonie/Add.svg';
export { ReactComponent as AddNewVersionMedium } from './medium/harmonie/Add new version.svg';
export { ReactComponent as CopyMedium } from './medium/harmonie/Copy.svg';
export { ReactComponent as CutMedium } from './medium/harmonie/Cut.svg';
export { ReactComponent as PasteMedium } from './medium/harmonie/Paste.svg';
export { ReactComponent as MusicMedium } from './medium/harmonie/Music.svg';
export { ReactComponent as ListMedium } from './medium/harmonie/List.svg';
export { ReactComponent as LocationMedium } from './medium/harmonie/Location.svg';
export { ReactComponent as PreviewMedium } from './medium/harmonie/Preview.svg';
export { ReactComponent as GenericFileMedium } from './medium/harmonie/Generic file.svg';
export { ReactComponent as ErrorItemMedium } from './medium/harmonie/Error icon.svg';
export { ReactComponent as VersionHistoryMedium } from './medium/harmonie/Version history.svg';
export { ReactComponent as ImageMedium } from './medium/harmonie/Image.svg';
export { ReactComponent as MenuMedium } from './medium/harmonie/Menu.svg';
export { ReactComponent as FavoriteFillMedium } from './medium/harmonie/Favorite selected.svg';
export { ReactComponent as CopyLinkMedium } from './medium/harmonie/CopyLink.svg';
export { ReactComponent as EditDetailsMedium } from './medium/harmonie/EditDetails.svg';
export { ReactComponent as FeedbackMedium } from './medium/harmonie/Feedback.svg';
export { ReactComponent as XMedium } from './medium/harmonie/X.svg';
export { ReactComponent as DropMedium } from './medium/harmonie/Drop.svg';
export { ReactComponent as BackToTop } from './medium/harmonie/BackToTop.svg';
export { ReactComponent as ReorderMedium } from './medium/harmonie/Reorder.svg';
export { ReactComponent as ReorderAToZMedium } from './medium/harmonie/Reorder.svg';
export { ReactComponent as ReorderZToAMedium } from './medium/harmonie/Z to A.svg';
export { ReactComponent as NewItemMedium } from './medium/harmonie/New item.svg';
export { ReactComponent as ErrorMedium } from './medium/harmonie/Error.svg';
export { ReactComponent as SimpleViewMedium } from './medium/harmonie/SimpleView.svg';
export { ReactComponent as DetailsViewMedium } from './medium/harmonie/DetailsView.svg';
export { ReactComponent as TypeMedium } from './medium/harmonie/Type.svg';
export { ReactComponent as NotificationSuccesseMedium } from './medium/harmonie/Notification success.svg';
export { ReactComponent as RemoveMedium } from './medium/harmonie/Remove.svg';

// microsoft icons
export { ReactComponent as OneDriveMedium } from './medium/microsoft/OneDrive.svg';
export { ReactComponent as OneMedium } from './medium/microsoft/One.svg';
export { ReactComponent as ChannelMedium } from './medium/microsoft/Channel.svg';
export { ReactComponent as OutlookMedium } from './medium/microsoft/Outlook.svg';
export { ReactComponent as PdfMedium } from './medium/microsoft/Pdf.svg';
export { ReactComponent as PptxMedium } from './medium/microsoft/Pptx.svg';
export { ReactComponent as TeamMedium } from './medium/microsoft/Team.svg';
export { ReactComponent as TeamsMedium } from './medium/microsoft/Teams.svg';
export { ReactComponent as TeamsFlatMedium } from './medium/microsoft/Teams flat.svg';

export { ReactComponent as XlsxMedium } from './medium/microsoft/Xlsx.svg';
export { ReactComponent as ChatMedium } from './medium/microsoft/Chat.svg';
export { ReactComponent as SharepointMedium } from './medium/microsoft/Sharepoint.svg';
export { ReactComponent as DocxMedium } from './medium/microsoft/Docx.svg';
export { ReactComponent as ExcelAppMedium } from './medium/microsoft/ExcelApp.svg';
export { ReactComponent as WordAppMedium } from './medium/microsoft/WordD.svg';
export { ReactComponent as PowerPointAppMedium } from './medium/microsoft/PowerPointApp.svg';
export { ReactComponent as SiteMedium } from './medium/microsoft/Site.svg';
export { ReactComponent as PrivateChannelMedium } from './medium/microsoft/Private channel.svg';
export { ReactComponent as VisioAppMedium } from './medium/microsoft/VisioApp.svg';
export { ReactComponent as ProjectAppMedium } from './medium/microsoft/ProjectApp.svg';
export { ReactComponent as VisioMedium } from './medium/microsoft/vsdx.svg';
export { ReactComponent as ProjectMedium } from './medium/microsoft/mpp.svg';
export { ReactComponent as MeetingNotesMedium } from './medium/microsoft/loop.svg';
export { ReactComponent as AvatarPersonMedium } from './medium/microsoft/AvatarPerson.svg';

//--------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------Large---------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------
export { ReactComponent as MicrosoftLarge } from './large/Microsoft.svg';
export { ReactComponent as DarkListDivider } from './large/DarkListDivider.svg';
export { ReactComponent as LightListDivider } from './large/LightListDivder.svg';
export { ReactComponent as TeamsLarge } from './large/Teams.svg';
export { ReactComponent as OneDriveLarge } from './large/OneDrive.svg';
export { ReactComponent as SharePointLarge } from './large/Sharepoint.svg';
export { ReactComponent as OutlookLarge } from './large/Outlook.svg';

export type SVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
