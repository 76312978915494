import { GraphClient } from '@services';
import { IODataContentType } from '@microsoft/sp-odata-types';
import { SharePointField } from '~/utilities/metadata/SharePointFieldType';
import { UploadDocument } from '../sharePointTypes';
import { OfficeUserSettingManager } from '../stateManager';
import { DocumentItem } from './DocumentItem';
import { AllowedItemType, ItemData } from './ItemData';
import { ListView } from './ListView';
import { LibraryItem } from './LibraryItem';
import { SharePointClient } from '../sharePointAPI';

export interface FetchChildrenProps {
  gcl: GraphClient;
  sortDir: boolean; // true - ascending
  refreshStamp?: number;
  itemCount?: number;
  isDrilldown?: boolean;
  pageToken?: string;
}

export interface ItemDataWithPaging {
  items: ItemData[];
  pageToken?: string;
  resultCount?: number;
  pagginationString?: string;
  view?: ListView;
  schema?: SharePointField[];
}

export interface Folder extends LibraryItem, Omit<ItemContainer, 'parent'> {
  get isDocumentSet(): boolean;
  get isOneNote();
  get list(): ItemContainer;
  upload(uploadProps: UploadDocument): Promise<DocumentItem>;
  getItemsFromView(
    gcl: GraphClient,
    view: ListView,
    count: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging>;
  createNewFolder(gcl: GraphClient, folderName: string): Promise<Folder>;
  getViews(gcl: GraphClient, includeHidden?: boolean, refreshStamp?: number): Promise<ListView[]>;
  resolve(gcl: GraphClient, view?: ListView): Promise<Folder>;
  onRefresh(gcl: GraphClient, refreshStamp?: number): Promise<void>;
  onLoading(gcl: GraphClient, refreshStamp?: number): Promise<void>;
  getSchema(spc: SharePointClient, refetch?: boolean): Promise<SharePointField[] | undefined>;
  getContentTypes(gcl: GraphClient, timestamp?: number): Promise<IODataContentType[]>;
  addOrCreateEmailContentType(gcl: GraphClient): Promise<IODataContentType>;
}

export interface ItemContainer extends ItemData {
  get supportSearch(): boolean;
  get supportsSortDirection();
  get stateCount(): OfficeUserSettingManager<number> | null;
  get filterState(): boolean | undefined;
  onChangeFilter(checked: boolean): void;
  newItemsCount?: number;
  fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging>;
  getFolder(gcl: GraphClient): Promise<Folder>;
  get fetchChildrenCount(): number;
  reorderItems(): void;
}

export const isItemContainer = (type: AllowedItemType) => {
  switch (type) {
    case 'insight':
    case 'document':
    case 'empty':
    case 'error':
    case 'viewfiles':
      return false;
  }
  return true;
};

export const typeHasFolder = (type: AllowedItemType) => {
  switch (type) {
    case 'channel':
    case 'folder':
    case 'list':
    case 'onedriveroot':
      return true;
  }
};
