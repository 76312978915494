import React, { FC } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { ThemeType } from '@vendor/types/ui';
import { useTheme } from '@harmon.ie-ce/storybook';
import { strings } from '@vendor/languages';
import { Typography } from '../Typography';
import { lightPaletteOptions, darkPaletteOptions } from '../../theme/palette';

const ThemeCard = styled(FormControlLabel)(() => ({
  margin: 0,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column-reverse',
  ['.MuiFormControlLabel-label']: {},
  ['.MuiRadio-root']: {
    padding: 0,
  },
}));

const ThemeCardBody = styled(Stack, {
  shouldForwardProp: prop => prop !== 'themeType' && prop !== 'checked',
})<{ themeType: ThemeType; checked: boolean }>(({ theme, themeType, checked }) => ({
  width: '90px',
  height: '90px',
  maxWidth: '90px',
  border: `${checked ? 2 : 1}px solid ${
    checked ? theme.palette.outlinesAndStrokes.accent : theme.palette.outlinesAndStrokes.secondary
  }`,
  borderRadius: '8px',
  flexDirection: 'row',
  overflow: 'hidden',
  padding: themeType === 'system' ? theme.spacing(0) : theme.spacing(3, 0, 0, 3),
  background: themeType === 'dark' ? darkPaletteOptions.backgrounds.primary : lightPaletteOptions.backgrounds.primary,
  ['.MuiBox-root']: {
    flex: 1,
    display: 'flex',
    borderRadius: themeType === 'system' ? '8px 0px 0px 8px' : '8px 0 8px 0',
    padding: themeType === 'system' ? theme.spacing(3, 0, 0, 3) : theme.spacing(1),
    background:
      themeType === 'system'
        ? lightPaletteOptions.backgrounds.primary
        : themeType === 'dark'
          ? darkPaletteOptions.backgrounds.secondary
          : lightPaletteOptions.backgrounds.secondary,
    color: themeType === 'dark' ? darkPaletteOptions.texts.primary : lightPaletteOptions.texts.primary,
    ['+.MuiBox-root']: {
      background: darkPaletteOptions.backgrounds.primary,
      borderRadius: 0,
      ['.MuiTypography-root']: {
        background: darkPaletteOptions.backgrounds.secondary,
        color: darkPaletteOptions.texts.primary,
        borderRadius: '8px 0',
      },
    },
    ['.MuiTypography-root']: {
      padding: themeType === 'system' ? theme.spacing(1) : theme.spacing(0),
      background: themeType === 'system' ? lightPaletteOptions.backgrounds.secondary : 'none',
      borderRadius: themeType === 'system' ? '8px 0 0 0' : '0',
    },
  },
}));

const CardContent: FC<{ theme: ThemeType; checked?: boolean }> = ({ theme, checked }) => {
  return (
    <ThemeCardBody checked={!!checked} themeType={theme}>
      <Box>
        <Typography variant="H1SemiBold">Aa</Typography>
      </Box>
      {theme === 'system' && (
        <Box>
          <Typography variant="H1SemiBold">Aa</Typography>
        </Box>
      )}
    </ThemeCardBody>
  );
};

export const ThemeSelector: React.FunctionComponent<{
  theme?: ThemeType;
  setTheme?: (theme?: ThemeType) => void;
}> = ({ theme, setTheme }) => {
  const uiTheme = useTheme();
  return (
    <FormControl>
      <Typography mb={2} color={uiTheme.palette.texts.primary} variant="H1SemiBold">
        {strings.lang.userSetting.themeSelectorTitle}
      </Typography>
      <RadioGroup
        row
        sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}
        aria-labelledby="theme-selector"
        name="theme"
        value={theme}
        onChange={e => setTheme?.(e.target.value as ThemeType)}
      >
        <ThemeCard
          checked={theme === 'system'}
          value="system"
          control={
            <Stack mt={2} spacing={2} direction="row">
              <Radio value="system" checked={theme === 'system'} />
              <Typography variant="H2Regular">{strings.lang.userSetting.themeSelectorOutlook}</Typography>
            </Stack>
          }
          label={<CardContent checked={theme === 'system'} theme={'system'} />}
        />
        <Box minWidth="5px" />
        <ThemeCard
          checked={theme === 'light'}
          value="light"
          control={
            <Stack mt={2} spacing={2} direction="row">
              <Radio value="light" checked={theme === 'light'} />
              <Typography variant="H2Regular">{strings.lang.userSetting.themeSelectorLight}</Typography>
            </Stack>
          }
          label={<CardContent checked={theme === 'light'} theme={'light'} />}
        />
        <Box minWidth="5px" />
        <ThemeCard
          checked={theme === 'dark'}
          value="dark"
          control={
            <Stack mt={2} spacing={2} direction="row">
              <Radio value="dark" checked={theme === 'dark'} />
              <Typography variant="H2Regular">{strings.lang.userSetting.themeSelectorDark}</Typography>
            </Stack>
          }
          label={<CardContent checked={theme === 'dark'} theme={'dark'} />}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ThemeSelector;
