import React from 'react';
import { Box } from '@mui/material';
import Marquee from 'react-marquee-slider';

export interface RunningLineProps {
  components: React.ReactNode[];
  velocity: number;
  direction: 'ltr' | 'rtl';
}

const RunningLine = ({ components, direction, velocity }: RunningLineProps) => {
  return (
    <Marquee
      velocity={velocity} // Speed of the marquee
      direction={direction} // Direction: 'ltr' or 'rtl'
      resetAfterTries={200} // Reset to ensure smoothness
      scatterRandomly={false} // Ensures smooth alignment
      onInit={() => {}}
      onFinish={() => {}}
    >
      {components.map((component, index) => (
        <Box
          key={index}
          sx={{
            display: 'inline-block',
            marginRight: '20px', // Spacing between images
            width: 'auto',
          }}
        >
          {component}
        </Box>
      ))}
    </Marquee>
  );
};

export default RunningLine;
