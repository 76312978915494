import { ShareLinkMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { OfficeMode, officeMode } from '~/misc';
import { ItemData } from '../itemTypes';
import {
  BaseAction,
  BaseReadActionClass,
  KeyModifiers,
  SelectedItemHandler,
  ShortcutAction,
  ShortcutActionRendererHookType,
} from './BaseAction';
import { emailWithLink } from './ActionsUtils';
import { InsightItem } from '../graphTypes';
import { OneDriveRoot } from '../virtualRoots';

const useEmailWithLink: ShortcutActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const onClick = useSafeCallback(
    async () => {
      await emailWithLink(gcl, nodes);
    },
    [gcl, nodes],
    false,
    nodes.length === 1 ? strings.lang.notifications.emailWithLink : strings.lang.notifications.emailWithLinks,
    undefined,
    undefined,
    nodes.length > 1 ? 'footer-indications' : '',
    undefined,
    3000
  );
  return {
    title:
      nodes.length == 1
        ? officeMode == OfficeMode.Read
          ? strings.lang.actionToolTip.emailWithLink
          : strings.lang.actionToolTip.addLink
        : officeMode == OfficeMode.Read
          ? strings.lang.actionToolTip.emailWithLinks
          : strings.lang.actionToolTip.addLinks,
    icon: ShareLinkMedium,
    chipLabel: strings.lang.shortcuts.emailWithLink,

    onClick,
  };
};

export class EmailWithLink extends BaseReadActionClass implements SelectedItemHandler, ShortcutAction {
  readonly trackedName = 'EmailWithLink';
  readonly useRenderer = useEmailWithLink;
  supportsTopLocation = true;
  isHandled = BaseAction.isMultiHandled(this);

  isSingleHandled = (d: ItemData) =>
    d.canOpenInBrowser && !(d instanceof OneDriveRoot) && !(d instanceof InsightItem && d.isEmailAttachment);

  shortcut: [KeyModifiers, string] = [KeyModifiers.Ctrl, 'e'];
}
