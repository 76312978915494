import { ViewItem, StarSmall } from '@storybook';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';

const useWhatsNew: ActionRendererHookType = ({ useCallback }) => {
  const onClick = useCallback(
    () => window.open('https://harmon.ie/documentation/sharepoint/new/whatsnew', '_blank'),
    []
  );
  return {
    title: strings.lang.allLocatiosToolbar.whatsNew,
    icon: StarSmall,
    onClick,
  };
};

export class WhatsNew extends BaseReadActionClass {
  readonly trackedName = 'WhatsNew';
  readonly useRenderer = useWhatsNew;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
