import { escapeRegExp } from 'lodash';
import { memberDetails } from '../networkAndCache';

export interface itemDetails {
  itemName: string;
  folderCrumb: string;
  itemTitle: string;
  itemGuid: string;
  itemListid: string;
  siteUrl: string;
  folderPath: string;
  isEmail: boolean;
}

export interface UrlParameters extends itemDetails {
  msgText: string;
  loginHint?: string;
  itemExt: string;
  shareUrl: string;
  lang: string;
  itemViewUrl?: string;
  channelId?: string;
  channelName?: string;
  teamId?: string;
  emailDate?: string;
  emailFrom?: string;
  emailTo?: string;
  emailBodySnippet?: string;
  emailHasAttachments?: boolean;
}

export const getUrlParameters = (url: URL, strings: any): UrlParameters => {
  const urlParams = new URLSearchParams(url.search);
  const isEmail = urlParams.get('isEmail') === 'true';
  const itemDetails: itemDetails = {
    ...(JSON.parse(urlParams.get('itemDetails') || '') as itemDetails),
    folderCrumb: urlParams.get('folderCrumb') || '',
    isEmail,
  };

  if (!isEmail) {
    const paramsObject: UrlParameters = {
      msgText: urlParams.get('msgText') || '',
      loginHint: urlParams.get('loginHint') || undefined,
      shareUrl: urlParams.get('shareUrl') || '',
      itemViewUrl: urlParams.get('itemViewUrl') || undefined,
      channelId: urlParams.get('channelId') || undefined,
      channelName: urlParams.get('channelName') || undefined,
      teamId: urlParams.get('teamId') || undefined,
      lang: urlParams.get('lang') || '',
      itemExt: urlParams.get('itemExt') || '',
      ...itemDetails,
    };
    return paramsObject;
  }

  const hasLetters = /\w/.test(urlParams.get('emailBodySnippet') || '');
  const paramsObject: UrlParameters = {
    msgText: urlParams.get('msgText') || '',
    loginHint: urlParams.get('loginHint') || undefined,
    shareUrl: urlParams.get('shareUrl') || '',
    itemViewUrl: urlParams.get('itemViewUrl') || undefined,
    channelId: urlParams.get('channelId') || undefined,
    channelName: urlParams.get('channelName') || undefined,
    teamId: urlParams.get('teamId') || undefined,
    itemExt: urlParams.get('itemExt') || '',
    lang: urlParams.get('lang') || '',
    emailFrom: urlParams.get('emailFrom') || undefined,
    emailDate: urlParams.get('emailDate') || undefined || undefined,
    emailTo: urlParams.get('emailTo') || undefined || undefined,
    emailBodySnippet: hasLetters ? urlParams.get('emailBodySnippet') || undefined : strings,
    emailHasAttachments: urlParams.get('emailHasAttachments') === 'true' || false,
    ...itemDetails,
  };

  return paramsObject;
};

export const copyLink = async (link: string) => {
  try {
    await navigator.clipboard?.writeText(link);
  } catch (e: any) {
    const temporaryInput = document.createElement('input');
    temporaryInput.style.opacity = '0';
    temporaryInput.value = link;
    document.body.appendChild(temporaryInput);
    temporaryInput.select();
    document.execCommand('copy');
    document.body.removeChild(temporaryInput);
  }
};

export const getGroupChatName = (members: memberDetails[], userName: string): string => {
  if (members.length === 3) {
    const otherMembers = members.filter(m => m.displayName !== userName).map(m => m.displayName.split(' ')[0]);
    return `${otherMembers[0]} and ${otherMembers[1]}`;
  }

  if (members.length === 4) {
    const otherMembers = members.filter(m => m.displayName !== userName).map(m => m.displayName.split(' ')[0]);
    return `${otherMembers[0]}, ${otherMembers[1]}, and ${otherMembers[2]}`;
  }

  if (members.length > 4) {
    const otherMembers = members.filter(m => m.displayName !== userName).map(m => m.displayName.split(' ')[0]);
    const remainingCount = members.length - 2;
    return `${otherMembers[0]} and ${otherMembers[1]} + ${remainingCount} more`;
  }

  // Default case if the above conditions are not met
  return '';
};

export const getDescriptionFromChat = (chat: any) => {
  const desc = [...chat.members].slice(0, 2);
  return (
    desc.map(m => m.displayName.split(' ')[0]).join(', ') +
    (chat.members.length - 2 > 0 ? ` +${chat.members.length - 2}` : '')
  );
};

export const getInitialName = (name?: string | null): string => {
  const avatarLetters = name && name.split(' ');
  if (avatarLetters) {
    const firstLetter = avatarLetters[0]?.charAt(0)?.toUpperCase();
    const lastLetter = avatarLetters[1]?.charAt(0)?.toUpperCase();
    return firstLetter + lastLetter || '';
  }
  return '';
};

export const nameMatchesFilter = (filter?: string) => {
  if (!filter) return () => true;
  const startWithFilter = new RegExp(`^${escapeRegExp(filter)}`, 'i');
  const afterNonWordFilter = new RegExp(`\\W${escapeRegExp(filter)}`, 'i');
  return (name: string) => {
    return Boolean(name.match(startWithFilter) || name.match(afterNonWordFilter));
  };
};

export const areArraysEqual = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort the arrays and then compare each element
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};
export const normalizeGuid = (guid: string, withBrackets: boolean): string => {
  if (!guid) return guid;
  const hasBrackets = guid.startsWith('{') && guid.endsWith('}');
  if (withBrackets) {
    return hasBrackets ? guid : `{${guid}}`;
  }
  return hasBrackets ? guid.slice(1, -1) : guid;
};
