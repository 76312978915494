import React, { useCallback, useContext, useMemo, useState } from 'react';
import { SaveEmailMedium, SaveEmailPopover, SaveEmailSmall, ViewItem } from '@storybook';
import { formatDateByTime } from '@vendor/utils/dates';
import { strings } from '@vendor/languages';
import { useGraphClient } from '@services';
import {
  OfficeMode,
  SelectedItemType,
  officeMode,
  supportsOfficeSelection,
  useSubscribeOfficeSelection,
} from '~/misc/office';
import UploadContext from '~/context/UploadContext';
import { Folder, ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, TrackedEventType } from './BaseAction';
import { UploadAction } from './UploadAction';
import { EmailUploadProvider } from '../uploadItem/EmailUploadProvider';

interface SaveEmailTooltip {
  subject?: string;
  date?: string;
  attachmentsCount?: number;
  sharedBy?: string;
}

// Function to convert ReadableStream to Blob
const useSaveEmailAction: ActionRendererHookType = ({ actionRef, actionVariant, useSafeCallback, nodes }) => {
  actionRef.current = [];
  const [items, setItems] = useState<SelectedItemType[]>([]);
  const [tooltipProperies, setTooltipProperties] = useState<SaveEmailTooltip>();
  const gcl = useGraphClient();
  const node = nodes[0];
  const { startUpload, beforeUpload } = useContext(UploadContext);

  useSubscribeOfficeSelection(
    useCallback((newItems: SelectedItemType[]) => {
      const curItem = Office.context?.mailbox?.item;
      // context item !== null and is singal item or is bulk saving!
      if ((newItems.length === 1 && curItem) || newItems.length > 1) {
        let attachmentCount = 0;
        if (curItem) {
          curItem.attachments?.forEach(attachment => {
            // Exclude inline attachments and signatures
            if (!attachment.isInline && attachment.attachmentType !== 'signature') {
              attachmentCount++;
            }
          });
        }
        const isSingalSelection = newItems.length === 1 && curItem;
        // Iterate through each attachment
        setTooltipProperties({
          subject: isSingalSelection ? curItem.subject || strings.lang.emailProps.noSubject : undefined,
          sharedBy: isSingalSelection ? curItem.sender?.displayName : `${newItems.length} emails`,
          date: isSingalSelection ? curItem.dateTimeCreated?.toString() : undefined,
          attachmentsCount: isSingalSelection ? attachmentCount : undefined,
        });
        // The office context item is null but the length is 1
      } else if (newItems.length === 1) {
        setTooltipProperties({
          subject: newItems[0].subject || strings.lang.emailProps.noSubject,
        });
      } else {
        setTooltipProperties(undefined);
      }

      setItems(newItems);
    }, [])
  );

  const curItem = items[0];
  const itemContainer = node.data as ItemContainer;

  const uploadEmail = useSafeCallback(...startUpload!);
  const saveEmailContent = async (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!actionVariant.withText) e.stopPropagation();
    const folder = await (node.data as ItemContainer).getFolder(gcl);
    const emailsProviders = items.map(item => new EmailUploadProvider(gcl, item, folder, node.data as ItemContainer));
    beforeUpload && (await beforeUpload(emailsProviders, actionRef, uploadEmail));
  };

  const tooltipTitle = useMemo(
    () =>
      items?.length === 0 ? (
        strings.lang.actionToolTip.saveEmailDisabled
      ) : (
        <SaveEmailPopover
          sharedBy={tooltipProperies?.sharedBy}
          fileName={tooltipProperies?.subject}
          date={formatDateByTime(tooltipProperies?.date, strings.lang.dateFormat)}
          attachmentsCount={tooltipProperies?.attachmentsCount}
          isMultipleEmails={items.length > 1}
        />
      ),
    [
      items?.length,
      tooltipProperies?.sharedBy,
      tooltipProperies?.subject,
      tooltipProperies?.date,
      tooltipProperies?.attachmentsCount,
    ]
  );
  if (!supportsOfficeSelection) return null; // no action in this case...
  return {
    title: strings.lang.saveEmailTitle!,
    icon: [SaveEmailSmall, SaveEmailMedium],
    onClick: saveEmailContent,
    actionVariant: actionVariant,
    tooltipTitle,
    hideAction:
      (!actionVariant.useMediumIcon && !curItem) ||
      (itemContainer.type == 'folder' && (itemContainer as Folder).isOneNote),
    tooltipClassName: curItem ? 'preview-tooltip' : 'default-tooltip',
    disabled: actionVariant.useMediumIcon && !curItem,
  };
};

export class SaveEmailAction extends UploadAction {
  constructor() {
    super('Save Email Button');
  }
  readonly useRenderer = useSaveEmailAction;
  transformEvent(tracked: TrackedEventType, ctx: any): TrackedEventType {
    return super.transformEvent(tracked, ctx);
  }

  isQuickAction(_data: ItemData, isTopBarItem: boolean) {
    return !isTopBarItem ? 'only' : undefined;
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.hasFolder && officeMode === OfficeMode.Read;
  }
}
