import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphClient } from '@services';
import { ItemDataWithPaging, RefreshAction } from '~/utilities';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import Drilldown from './Drilldown';

const SimpleDrilldown = () => {
  const {
    state: { location, refreshStamp, newItems, itemCount },
  } = useLocation() as DrilldownState;
  const gcl = useGraphClient();
  const get = useCallback(
    async (token?: string): Promise<ItemDataWithPaging> => {
      const nrefreshStamp = await RefreshAction.markRefreshStamp(location, refreshStamp);
      return await location.fetchChildren({
        gcl,
        refreshStamp: nrefreshStamp,
        itemCount,
        isDrilldown: true,
        sortDir: true,
        pageToken: token,
      });
    },
    [location, refreshStamp, gcl, itemCount]
  );
  return (
    <Drilldown
      key={location.apiIdKey}
      enableToolbarActions={true}
      newItems={newItems}
      location={location}
      getItems={get}
    />
  );
};

export default SimpleDrilldown;
