import { sha256 } from 'js-sha256';
import { GraphClient } from '@services';
import { UndoAction, IconProps } from '@storybook';
import { AccessUrls, AllowedItemType, getChildrenType, ItemData, NoRootSite, treeitemFirstParent } from './ItemData';
import { isItemContainer, ItemContainer } from './ItemContainer';
import { SharePointFieldType } from '../metadata/SharePointFieldType';
import { serializationVersion } from '../misc';

export interface BaseItemProps {
  name: string;
  id: string;
  isNew?: boolean;
  timeStamp?: number;
}

export interface ExBaseItemProps extends BaseItemProps {
  type: AllowedItemType;
}

export abstract class BaseItem implements ItemData {
  readonly type: AllowedItemType;
  readonly name: string;
  id: string;
  ownPrefix: boolean;
  getChildren: getChildrenType | undefined;
  objectify: (props: any) => ItemData;
  private _rootPrefix: string;
  isTransientLocation?: boolean;
  isNew?: boolean;
  isSearchItem?: boolean;
  isAdvancedSearchItem: boolean;
  searchedLocationId?: string;
  summary?: string;

  protected constructor({ type, name, id }: ExBaseItemProps) {
    this.name = name;
    this.id = id;
    this.type = type;
    this.objectify = BaseItem.getObjectify(this);
    this.ownPrefix = false;
    this._rootPrefix = '';
  }
  itemColorable = true;
  size?: number | undefined;
  isVirtualItem?: boolean | undefined;
  itemFirstParent?: treeitemFirstParent | undefined;
  timeStamp?: number | undefined;

  get isAdvancedSearchContainer(): boolean {
    return false;
  }
  resetNewItems(_isOpened: boolean): void {
    // Implement the function as needed - favorties use this function to remove the new items count
  }
  get apiId() {
    return this.rootPrefix ? this.id.substring(this.rootPrefix.length) : this.id;
  }
  get apiIdKey() {
    return this.apiId.toLowerCase();
  }
  get sortDir(): boolean {
    return false;
  }
  get hasNewChildren(): boolean {
    return false;
  }
  get locationHaveQuickSearch(): boolean {
    return true;
  }
  get supportsSelection(): boolean {
    return !this.isRootSection && !this.isVirtual;
  }
  get canReorder(): boolean {
    return false;
  }
  get parent(): ItemContainer | undefined {
    return undefined;
  }
  get pathOrDescription(): string | null {
    return null;
  }
  get isDropArea(): boolean {
    return false;
  }
  get secondLineContent(): string | null {
    return null;
  }
  get canRemove(): boolean {
    return false;
  }
  get canUndoRemove(): boolean {
    return false;
  }
  get isTeamsEntity(): boolean {
    return false;
  }
  get canRefresh(): boolean {
    return isItemContainer(this.type);
  }
  get canOpenInBrowser() {
    return !this.isVirtual;
  }
  get canDownload() {
    return false; // Currently disabled for all platforms
  }
  get isRootSection(): boolean {
    return false;
  }
  get isVirtual(): boolean {
    return this.isRootSection;
  }
  get isDocument(): boolean {
    return false;
  }
  get isLibraryItem(): boolean {
    return false;
  }
  get isShareable() {
    return this.isDocument || this.type == 'folder';
  }
  get isUserManaged() {
    return false;
  }
  get hasFolder(): boolean {
    return false;
  }
  get rootSite(): string {
    return NoRootSite;
  }
  get rootPrefix() {
    return this._rootPrefix;
  }
  set rootPrefix(val) {
    const apiId = this.apiId;
    if (this.ownPrefix) this._rootPrefix = val + sha256(apiId);
    else this._rootPrefix = val;
    this.id = this._rootPrefix + apiId;
  }

  getNumberOfQuickActions(_isDrilldown: boolean): number {
    return this.hasFolder ? 3 : 2;
  }

  setOwnPrefix() {
    this.ownPrefix = true;
  }

  abstract getIcon(expanded: boolean): IconProps | undefined;

  // Since node data is cloned by ther tree (& probably react), it detaches from its class.
  // Reattch it if necessary.
  static getObjectify(that: BaseItem) {
    const obj = {};
    Object.setPrototypeOf(obj, that);
    return (props: ItemData) => {
      if (props instanceof BaseItem) return props as BaseItem;
      return Object.setPrototypeOf(props, obj) as BaseItem;
    };
  }

  async getAccessUrls(_gcl: GraphClient): Promise<AccessUrls | undefined> {
    return undefined;
  }

  protected fillJson(res: any): void {
    res.name = this.name;
    res.id = this.apiId;
    res.type = this.type;
    res.isNew = this.isNew;
    res.version = serializationVersion;
  }

  toJson(): any {
    const res = {};
    this.fillJson(res);
    return res;
  }

  async removeItem(_gcl: GraphClient): Promise<void | UndoAction> {
    return;
  }

  getProperty(_name: string, _type: SharePointFieldType): string {
    return '';
  }
}
