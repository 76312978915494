export function asType<T>(arg: T): T {
  return arg;
}

// Allows us to run a network call from a provider exactly once (unless reset is true).
// The use case is that when getting list views or scheme, we call the method from different places.
// Requires more debugging to ensure no race conditions can happen.
export async function runSingleAsyncCall<T>(
  owner: object,
  reset: boolean | undefined,
  opName: string,
  op: () => Promise<T>
): Promise<T> {
  opName = `_temp_operation_${opName}`;
  const curRunningOp = reset ? op() : (owner[opName] as Promise<T>) ?? op();
  owner[opName] = curRunningOp;
  const res = await curRunningOp;
  delete owner[opName];
  return res;
}
