import {
  InsightIdentity,
  SharedInsight,
  UsedInsight,
  NullableOption,
  DriveItem,
  Attachment,
  ListItem,
  SharepointIds,
  FileAttachment,
} from '@microsoft/microsoft-graph-types';
import { AccountInfo } from '@azure/msal-browser';
import { UsageType, getMediaTypeIcon, MediaKeysType, IconProps } from '@storybook';
import { max as _max } from 'lodash';
import {
  FolderExpirationWindow,
  GraphBatchRequest,
  GraphClient,
  EternalWindow,
  ThumbnailExpirationWindow,
  normalizeGuid,
  GraphWebUrlKeys,
} from '@services';
import {
  DocumentAccessUrls,
  BaseItem,
  BaseItemProps,
  DocumentItem,
  DownloadData,
  SupportedOfficeAppTypes,
  ThumbnailData,
  ShareableItem,
  MicrosoftApps,
} from '../itemTypes';
import {} from '../misc';
import { getLastWeekIso, isLater } from '../misc/utils';
import { SharePointClient, getSharPointRelativeUrl } from '../sharePointAPI';

interface InsightItemProps extends BaseItemProps {
  isAttachment: boolean;
  mediaType: string;
  itemType: string;
  usageType?: UsageType;
  webUrl: string;
  containerWebUrl: string;
  size?: number;
  user?: InsightIdentity;
  date?: NullableOption<string> | undefined;
  listItem?: ListItem;
  sharepointIds?: SharepointIds;
  subject?: string;
  isEmailAttachment?: boolean;
}

const isRecentOrLater = (date1?: NullableOption<string>, date2?: NullableOption<string>) => {
  return isLater(date1, date2) || isLater(date1, getLastWeekIso());
};

export const InsightTypedFilter =
  "(resourceVisualization/type ne 'web') AND (resourceVisualization/type ne 'spsite') AND (resourceVisualization/type ne 'image') AND (resourceVisualization/type ne 'folder') AND (resourceVisualization/type ne 'other') AND (resourceVisualization/type ne 'externalcontent')";

export const FilterEmailAttachments = "AND (resourceReference/type ne 'microsoft.graph.fileAttachment')";
export class InsightItem extends BaseItem implements DocumentItem, ShareableItem {
  readonly isAttachment: boolean;
  readonly isEmailAttachment: boolean;
  readonly mediaType: MediaKeysType;
  readonly itemType: string;
  readonly _usageType?: UsageType;
  readonly webUrl: string;
  readonly containerWebUrl: string;
  readonly _user?: InsightIdentity;
  readonly size?: number;
  readonly _date: string;
  readonly _subject?: string;
  listItem?: ListItem;
  sharepointIds?: SharepointIds;

  private static getUsageType(item: Insight, curUser: AccountInfo, isTeamChatItem?: boolean): UsageType {
    // UsageType is complex since we have many cases:
    // Current user 'you-...', Teams chat files is different from recent or shared, etc. etc.
    const byCurUser = !item.lastShared || item.lastShared?.sharedBy?.address === curUser.username;
    const { lastShared, lastUsed } = item;
    const isAttachment = item.lastShared?.sharingType === 'Attachment';
    const isEmailAttachment = item.resourceReference?.type === 'microsoft.graph.fileAttachment';
    if (isEmailAttachment && !byCurUser) return 'email-attachment';
    if (isAttachment && isTeamChatItem) return byCurUser ? 'you-shared-in-team-chat' : 'shared-with-you-in-team-chat';
    else if (isEmailAttachment) {
      return byCurUser ? 'you-sent' : 'sent';
    } else if (isRecentOrLater(lastShared?.sharedDateTime, lastUsed?.lastAccessedDateTime))
      return byCurUser ? 'you-shared' : 'shared';
    else if (byCurUser && isRecentOrLater(lastUsed?.lastModifiedDateTime, lastUsed?.lastAccessedDateTime))
      return 'you-edited';
    return byCurUser ? 'you-used' : 'shared';
  }

  static async resolveSharepointIds(gcl: GraphClient, items: InsightItem[]) {
    let gb = new GraphBatchRequest(gcl.client);
    const fetchRequests: {
      listItemRequest: () => Promise<ListItem>;
      sharePointIdsRequest: () => Promise<SharepointIds>;
      item: InsightItem;
    }[] = [];
    for (const item of items) {
      if (item.isEmailAttachment) continue; // Can't resolve SP ids from Exchange
      if (!gb.canAddRequests(2)) {
        // Run the batch and reset if batch limit is reached
        await gb.runAll();
        gb = new GraphBatchRequest(gcl.client);
      }
      fetchRequests.push({
        listItemRequest: await gb
          .api(`${item.apiId}/listItem`)
          .cache(FolderExpirationWindow)
          .select(GraphWebUrlKeys)
          .get(),
        sharePointIdsRequest: await gb.api(`${item.apiId}/listItem/sharepointIds`).cache(EternalWindow).get(),
        item,
      });
    }
    await gb.runAll();
    for (let i = 0; i < fetchRequests.length; i++) {
      const listItem = await fetchRequests[i].listItemRequest();
      const sharePointIds = await fetchRequests[i].sharePointIdsRequest();
      fetchRequests[i].item.setSharePointIds(listItem, sharePointIds);
    }
    return items;
  }

  static fromInsight(item: Insight, curUser: AccountInfo, isTeamChatItem?: boolean, itemSize?: number) {
    const { lastShared, lastUsed, listItem, sharePointIds } = item;
    const isDriveItem = item?.resourceVisualization?.containerType === 'OneDriveBusiness';
    const isAttachment = item.lastShared?.sharingType === 'Attachment';
    const usageType = this.getUsageType(item, curUser, isTeamChatItem);
    const isEmailAttachment = item.resourceReference?.type === 'microsoft.graph.fileAttachment';
    return new InsightItem({
      id: item.resourceReference?.id || item.id || '',
      name: item.resourceVisualization?.title || '',
      isAttachment: isAttachment && !isDriveItem,
      itemType: item.resourceReference?.type || '',
      mediaType: item.resourceVisualization?.type || '',
      usageType,
      isEmailAttachment: isEmailAttachment,
      subject:
        isEmailAttachment && item.lastShared && item.lastShared.sharingSubject
          ? item.lastShared.sharingSubject
          : undefined,
      webUrl: item.resourceReference?.webUrl || '',
      user: lastShared?.sharedBy || undefined,
      containerWebUrl: item.resourceVisualization?.containerWebUrl || '',
      size: itemSize,
      date: lastShared
        ? lastShared.sharedDateTime
        : _max([lastUsed?.lastAccessedDateTime, lastUsed?.lastModifiedDateTime]),
      listItem,
      sharepointIds: sharePointIds,
    });
  }

  constructor(props: InsightItemProps) {
    super({ ...props, type: 'insight' });
    this.listItem = props.listItem;
    this.sharepointIds = props.sharepointIds;
    this.isAttachment = props.isAttachment;
    this._usageType = props.usageType;
    this.mediaType = props.mediaType as MediaKeysType;
    this.itemType = props.itemType;
    this.webUrl = props.webUrl;
    this.containerWebUrl = props.containerWebUrl;
    this._date = props.date || '';
    this._user = props.user;
    this._subject = props.subject;
    this.isEmailAttachment = Boolean(props.isEmailAttachment);
  }
  async resolve(_gcl: GraphClient): Promise<DocumentItem> {
    return this;
  }

  additionalActionHandler?: ((e: React.MouseEvent) => void) | undefined;

  protected fillJson(res: InsightItemProps): void {
    super.fillJson(res);
    res.listItem = this.listItem;
    res.sharepointIds = this.sharepointIds;
    res.isAttachment = this.isAttachment;
    res.usageType = this.usageType;
    res.mediaType = this.mediaType;
    res.itemType = this.itemType;
    res.webUrl = this.webUrl;
    res.containerWebUrl = this.containerWebUrl;
    res.user = this.user;
    res.date = this.date;
  }

  setSharePointIds(listId?: ListItem, sharePointIds?: SharepointIds) {
    this.listItem = listId;
    this.sharepointIds = sharePointIds;
  }

  get hasPreview() {
    if (this.isEmailAttachment) return false;
    else return !this.getIcon(false).noPreview;
  }
  override get isDocument(): boolean {
    return true;
  }
  override get isShareable(): boolean {
    return !this.isEmailAttachment;
  }
  get canOpenInBrowser() {
    return InsightItem.hasWebViewer(this.mediaType);
  }
  get officeApp(): SupportedOfficeAppTypes | undefined {
    if (this.isEmailAttachment) return; // We cannot handle office apps on Exchange attachments
    return InsightItem.getOfficeType(this.mediaType);
  }
  get date(): string {
    return this._date;
  }
  get user(): InsightIdentity | undefined {
    return this._user;
  }
  get usageType(): UsageType | undefined {
    return this._usageType;
  }
  get OfficeAppLocated() {
    return MicrosoftApps.Default;
  }
  get allowEdit(): boolean {
    switch (this.usageType) {
      case 'you-edited':
      case 'you-sent':
      case 'you-shared':
      case 'you-shared-in-team-chat':
      case 'you-used':
        return true;
    }
    return false;
  }

  static getOfficeType(mediaType: MediaKeysType): SupportedOfficeAppTypes | undefined {
    switch (mediaType) {
      case 'Word':
      case 'Excel':
      case 'PowerPoint':
      case 'Project':
        return mediaType;
      case 'Csv':
        return 'Excel';
    }
  }

  static hasWebViewer(media: MediaKeysType) {
    switch (media) {
      case 'Other':
      case 'ExternalContent':
      case 'Spsite':
      case 'Story':
      case 'Web':
        return false;
    }
    return true;
  }

  override getIcon(_expanded: boolean): IconProps {
    return getMediaTypeIcon(this.mediaType);
  }

  static async getThumbnail(gcl: GraphClient, driveId: string, defName: string): Promise<ThumbnailData | undefined> {
    try {
      const fullData: DriveItem = await gcl
        .api(driveId)
        .headers({ 'Cache-Control': 'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate' })
        .cache(ThumbnailExpirationWindow)
        .expand('thumbnails')
        .select(['id', 'name', 'thumbnails'])
        .get();
      const thumbnails = (fullData?.thumbnails || [])[0];
      return {
        thumbNail: (thumbnails?.large || thumbnails?.medium || thumbnails?.small)?.url || undefined,
        name: fullData.name || defName,
      };
    } catch (error) {}
  }

  static getAppUrl(type?: string, url?: NullableOption<string>) {
    return (type && url && `ms-${type.toLowerCase()}:ofe|u|${url}`) || undefined;
  }

  static generateODOpenURL(
    fileId: string,
    siteId: string,
    listId: string,
    webUrl: string,
    filename: string,
    userId: string,
    userEmail: string
  ) {
    if (Office?.context?.mailbox?.userProfile?.emailAddress && filename)
      return (
        `odopen://openFile/?` +
        `fileId=${encodeURIComponent(normalizeGuid(fileId, false))}` + //without curly brackets
        `&siteId=${encodeURIComponent(normalizeGuid(siteId, true))}` + //with curly brackets
        `&listId=${encodeURIComponent(normalizeGuid(listId, true))}` + //with curly brackets
        `&userEmail=${encodeURIComponent(userEmail)}` +
        `&userId=${encodeURIComponent(userId)}` +
        `&webUrl=${encodeURIComponent(webUrl)}` +
        `&fileName=${encodeURIComponent(filename)}`
      );
    return undefined;
  }

  async removeItem(gcl: GraphClient) {
    await gcl.api(`${this.apiId}/recycle()`).post(null);
  }
  get subject() {
    return this._subject;
  }

  async getAccessUrls(gcl: GraphClient): Promise<DocumentAccessUrls> {
    if (this.isEmailAttachment) {
      const fullData: Attachment = await gcl.api(this.apiId).cache(FolderExpirationWindow).select('name').get();
      return {
        webUrl: this.webUrl,
        downloadUrl: `${this.apiId}`,
        fileName: fullData.name,
      } as DocumentAccessUrls;
    }

    const app = this.officeApp ? 'Doc.aspx' : 'Viewer.aspx';
    const account = gcl.msal.getActiveAccount() || gcl.msal.getAllAccounts()[0];
    if (!this.sharepointIds)
      this.sharepointIds = await gcl.api(`${this.apiId}/listItem/sharepointIds`).cache(EternalWindow).get();
    if (!this.listItem)
      this.listItem = await gcl.api(`${this.apiId}/listItem`).cache(EternalWindow).select(GraphWebUrlKeys).get();
    return {
      webUrl: `${this?.sharepointIds?.siteUrl}/_layouts/15/${app}?sourceDoc={${this?.sharepointIds?.listItemUniqueId}}`,
      downloadUrl: `${this?.sharepointIds?.siteUrl}/_api/Web/lists('${this?.sharepointIds?.listId}')/items('${this?.sharepointIds?.listItemId}')/file/$value?binaryStringResponseBody=true`,
      appUrl: InsightItem.getAppUrl(this.officeApp, this.listItem?.webUrl),
      fileName: decodeURIComponent(this.listItem?.webUrl?.substring(this.listItem?.webUrl?.lastIndexOf('/') + 1) || ''),
      odOpenUrl:
        this?.sharepointIds?.siteId && this?.sharepointIds?.listId && this.sharepointIds.listItemUniqueId
          ? InsightItem.generateODOpenURL(
              this.sharepointIds.listItemUniqueId,
              this.sharepointIds.siteId,
              this.sharepointIds.listId,
              this.sharepointIds.siteUrl || '',
              this.listItem?.webUrl?.split('/').pop() || '',
              account.homeAccountId,
              account.username
            )
          : '',
      // DecodeURI will decode '%25' to '%', so to leave it encoded we replace it with %2525...
      address: decodeURI(this.listItem?.webUrl?.replaceAll('%25', '%2525') || ''),
    } as DocumentAccessUrls;
  }

  async download(gcl: GraphClient): Promise<DownloadData> {
    const urls = await this.getAccessUrls(gcl);
    const dUrl = urls.downloadUrl;
    let fetchResult: Response;
    let fileName: string;
    if (this.isEmailAttachment) {
      const bits: FileAttachment = await gcl.api(dUrl).get();
      const ctType = bits.contentType || 'application/octet-stream';
      fetchResult = await fetch(`data:${ctType};base64,${bits.contentBytes}`);
      fileName = bits.name || '';
    } else {
      const spc = new SharePointClient(gcl, new URL(dUrl).origin);
      fetchResult = await spc.api(dUrl).getStream();
      fileName = urls['fileName']; // We get it OOB from getAccessUrls...
    }
    return { blob: await fetchResult.blob(), fileName };
  }

  async getDetailsUrlByType(type: string, gcl: GraphClient): Promise<string> {
    const itemDedails: SharepointIds = await gcl.api(`${this.apiId}/listItem/sharepointIds`).cache(EternalWindow).get();
    const typeMapper = {
      edit: 'EditForm',
      view: 'DispForm',
    };
    return `${getSharPointRelativeUrl(this.containerWebUrl, itemDedails.siteUrl || '')}/Forms/${
      typeMapper[type]
    }.aspx?ID=${itemDedails.listItemId}`;
  }

  async getThumbnail(gcl: GraphClient): Promise<ThumbnailData | undefined> {
    try {
      if (this.itemType === 'microsoft.graph.fileAttachment') {
        const fullData: Attachment = await gcl.api(this.apiId).cache(FolderExpirationWindow).select('name').get();
        return {
          name: fullData.name || this.name,
        };
      }
      if (this.itemType !== 'microsoft.graph.driveItem') return;
      return await InsightItem.getThumbnail(gcl, this.apiId, this.name);
    } catch (error) {}
  }
}

export type Insight = SharedInsight &
  UsedInsight & {
    listItem?: ListItem; // Optional property for listItem
    sharePointIds?: SharepointIds; // Optional property for sharePointIds
  };
