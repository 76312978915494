import React, { useCallback, useMemo, useRef, useState } from 'react';
import Indicator from '@harmon.ie-ce/storybook/src/components/Indicator/Indicator';
import { IndicatorTexts } from '@vendor/languages';
import { calculateProgress } from '@vendor/utils/misc';
import { MenuAction } from '@storybook';
import IndicatorContext, { ProgressData } from './IndicatorContext';

const initialProgressData: ProgressData = {
  dateKey: '',
  showDialog: false,
  activeFileIndex: -1,
  status: 'progress',
  currentProgress: 0,
  fileProgress: [],
  streamFiles: [],
};

export const IndicatorProvider = (props: any) => {
  const needAbortCurrentRequest = useRef<boolean>(false);
  const [progressData, setProgressData] = useState<ProgressData>(initialProgressData);
  const [indicatorTexts, setIndicatorTexts] = useState<IndicatorTexts>();
  const [className, setClassName] = useState<string>();
  const [postSuccessActions, setPostSuccessActions] = useState<MenuAction[]>();
  const inProgress = useRef(false);
  const currentProgressIndex = useRef<number>(0);
  const savedProgress = useRef<ProgressData>(initialProgressData); // Reference to save progress state
  const onCloseCallback = useCallback(() => {
    needAbortCurrentRequest.current = false;
    setProgressData(data => ({
      ...data,
      showDialog: false,
    }));
  }, []);
  const applyClassName = (className?: string) => setClassName(className);
  const startProgressIndication = useCallback(
    (fileCount: number, indicatorTexts: IndicatorTexts, streamFiles?: string[]) => {
      setIndicatorTexts(indicatorTexts);
      onCloseCallback();
      inProgress.current = true;
      setProgressData(data => ({
        ...data,
        dateKey: new Date().toISOString(),
        showDialog: true,
        streamFiles: streamFiles || [],
        currentProgress: 0,
        fileProgress: [...new Array(fileCount).fill(false)],
        status: 'progress',
      }));
    },
    [onCloseCallback]
  );

  const oneItemStartIndication = useCallback((activeFileIndex: number) => {
    currentProgressIndex.current = activeFileIndex;
    setProgressData(data => ({
      ...data,
      activeFileIndex,
      currentProgress: 0,
    }));
  }, []);

  const oneItemCompletedIndication = useCallback((index: number) => {
    setProgressData(data => ({
      ...data,
      activeFileIndex: -1,
      fileProgress: [...data.fileProgress.slice(0, index), true, ...data.fileProgress.slice(index + 1)],
    }));
  }, []);

  const successfulProcessIndication = useCallback(() => {
    inProgress.current = false;
    setProgressData(data =>
      data.status === 'progress'
        ? {
            ...data,
            activeFileIndex: -1,
            status: 'success',
          }
        : data
    );
  }, []);

  const errorfulProcessIndication = useCallback((message: string) => {
    inProgress.current = false;
    setProgressData(data =>
      data.status === 'progress'
        ? {
            ...data,
            currentProgress: 0,
            status: 'error',
            activeFileIndex: -1,
            errorMessage: message,
          }
        : data
    );
  }, []);

  const progressRequestHandler = useMemo(() => {
    return {
      updateProgress: (index: number, currentProgress: number) => {
        setProgressData(data => ({
          ...data,
          currentProgress: index === data.activeFileIndex ? currentProgress : data.currentProgress,
        }));
      },
      saveProgressState: () => {
        savedProgress.current = { ...progressData };
      },
      resetProgressState: () => {
        setProgressData(savedProgress.current);
      },
      getCurrentProgressIndex: (): React.MutableRefObject<number> => {
        return currentProgressIndex;
      },
      needToCancel: () => needAbortCurrentRequest.current,
      inProgress: () => inProgress.current,
      setSuccessIndicatorActions: (actions: MenuAction[]) => {
        setPostSuccessActions(actions);
      },
    };
  }, [progressData]);

  return (
    <IndicatorContext.Provider
      value={{
        progressRequestHandler,
        setProgressData,
        oneItemCompletedIndication,
        startProgressIndication,
        oneItemStartIndication,
        errorfulProcessIndication,
        successfulProcessIndication,
        applyClassName,
      }}
    >
      {props.children}
      {indicatorTexts && (
        <Indicator
          dateKey={progressData.dateKey}
          messageBody={progressData.streamFiles}
          status={progressData.status}
          fileProgress={progressData.fileProgress}
          currentProgress={progressData.currentProgress}
          activeFileIndex={progressData.activeFileIndex}
          onComplete={successfulProcessIndication}
          onCancel={() => {
            inProgress.current = false;
            setProgressData(data => ({ ...data, status: 'canceled' }));
            needAbortCurrentRequest.current = true;
          }}
          onClose={onCloseCallback}
          onExited={() => setProgressData(initialProgressData)}
          open={progressData.showDialog}
          isCanceling={progressData.status === 'canceled'}
          errorMessage={progressData.errorMessage}
          currentProgressIndex={currentProgressIndex.current + 1}
          indicatorStrings={indicatorTexts}
          calculateProgress={calculateProgress}
          className={className}
          postSuccessActions={postSuccessActions}
        />
      )}
    </IndicatorContext.Provider>
  );
};
