import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { ButtonProps, Dialog, Checkbox, ButtonsStack, Stack } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import {
  DocumentItem,
  isUrlItemData,
  ItemData,
  crossPlatformDownload,
  OpenDocumentContext,
  createSafe2CallbackWrap,
  OpenDocumentInBrowserAction,
} from '~/utilities';
import { OpenAction } from '~/utilities/misc/utils';
import { useSafeCallback, useSafeCallbackType } from '~/hooks/UseSafeCallback';
import {
  DownloadOrBrowserState,
  OpenInDesktopAppOrBrowserState,
  useSettings,
} from '~/modules/Settings/SettingContext/SettingContext';
import RenderTooltip from './RenderTooltip';
import { ItemDataRenderer, ItemDataRenderProps, ItemDataRenderWithNextProps } from './GetNodeRenderer';

const canOpenInApp = (data: DocumentItem) => {
  return (
    data.mediaType === 'Csv' ||
    data.mediaType === 'Excel' ||
    data.mediaType === 'PowerPoint' ||
    data.mediaType === 'Word' ||
    data.mediaType === 'Project'
  );
};
const generateButtons = (
  isDocument: boolean,
  [onOpenInBrowser, onDownload, onOpenInApp]: Array<() => void>,
  strings: Record<string, string>
): ButtonProps[] =>
  isDocument
    ? [
        {
          children: strings.openInBrowserApp,
          variant: 'secondary',
          onClick: onOpenInBrowser,
        },
        {
          children: strings.openInApp,
          variant: 'primary',
          onClick: onOpenInApp,
        },
      ]
    : [
        {
          children: strings.downloads,
          variant: 'secondary',
          onClick: onDownload,
        },
        {
          children: strings.openInBrowser,
          variant: 'primary',
          onClick: onOpenInBrowser,
        },
      ];

const RenderUserChoiceDialog: React.FC<{
  data: ItemData;
  props: ItemDataRenderWithNextProps;
  useSafeCallback: useSafeCallbackType;
  actionRef: MutableRefObject<OpenDocumentContext>;
}> = ({ data, props: { nextRenderer, ...props }, useSafeCallback, actionRef }) => {
  // TODO: change when we have multi lang
  const { setOpenFilesSettings } = useSettings();
  const texts = useMemo(() => strings.lang.doubleClickAction, []);
  const canOpenInDesktopApp = canOpenInApp(data as DocumentItem);
  const chosenAction = canOpenInDesktopApp ? OpenInDesktopAppOrBrowserState : DownloadOrBrowserState;
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [checked, setCheck] = useState<boolean>(true);
  const nData = data as DocumentItem;
  const gcl = useGraphClient();

  const runAction = useSafeCallback(
    async (action: OpenAction) => {
      actionRef.current = {
        mode: action,
        mediaType: nData.mediaType,
      };
      switch (action) {
        case OpenAction.IN_APP:
          const AccessUrls = await nData.getAccessUrls(gcl);
          window.open(AccessUrls.appUrl, '_blank');
          break;
        case OpenAction.OPEN:
          const webUrl = (await nData.getAccessUrls(gcl)).webUrl;
          window.open(webUrl, '_blank');
          break;
        case OpenAction.DOWNLOAD:
          crossPlatformDownload(nData, gcl);
          break;
      }
    },
    [actionRef, gcl, nData],
    false,
    {
      onSuccessTitle: strings.lang.notifications.openDocumentNotifications(nData.mediaType.toLowerCase()),
    }
  );

  const onDoubleClick = useCallback(() => {
    if (isUrlItemData(data)) {
      runAction(OpenAction.OPEN);
    } else if (!data.canOpenInBrowser) {
      runAction(OpenAction.DOWNLOAD);
    } else if (!canOpenInDesktopApp && !data.canDownload) {
      runAction(OpenAction.OPEN);
    } else if (chosenAction.value && chosenAction.value !== OpenAction.CHOOSE) {
      runAction(chosenAction.value);
    } else {
      setDialogVisible(true);
    }
  }, [canOpenInDesktopApp, chosenAction.value, data, runAction]);

  const onClose = useCallback(
    (withAction?: OpenAction) => {
      if (withAction) {
        if (checked) {
          chosenAction.value = withAction;
          setOpenFilesSettings?.(withAction);
        }

        runAction(withAction);
      }
      setDialogVisible(false);
    },
    [checked, chosenAction, runAction, setOpenFilesSettings]
  );

  const buttonRow: ButtonProps[] = useMemo(
    () =>
      generateButtons(
        canOpenInDesktopApp,
        [() => onClose(OpenAction.OPEN), () => onClose(OpenAction.DOWNLOAD), () => onClose(OpenAction.IN_APP)],
        texts
      ),
    [canOpenInDesktopApp, texts, onClose]
  );

  return (
    <>
      {nextRenderer({ ...props, onDoubleClick, RenderTooltip })}
      <Dialog
        onClick={e => e.stopPropagation()}
        onClose={() => onClose()}
        hasCloseAction={true}
        actions={<ButtonsStack buttons={buttonRow} />}
        open={!!dialogVisible}
        title={canOpenInDesktopApp ? texts.appDialogTitle : texts.dialogTitle}
        maxWidth="xs"
        titleClassName="user-question-dialog-title"
      >
        <Stack spacing={2}>
          <Checkbox
            checked={checked}
            onChange={() => setCheck(!checked)}
            label={texts.checkboxLabel}
            className="default-checkbox"
          />
        </Stack>
      </Dialog>
    </>
  );
};

const InsightRenderer = ({ nextRenderer, ...props }: ItemDataRenderWithNextProps) => {
  const actionRef = useRef<OpenDocumentContext>({ mode: OpenAction.CHOOSE });
  const safeCallbackWrap = createSafe2CallbackWrap(
    new OpenDocumentInBrowserAction(undefined),
    'Double Click',
    [props.node],
    useSafeCallback,
    props.handlers,
    actionRef
  ) as useSafeCallbackType;
  return (
    <RenderUserChoiceDialog
      useSafeCallback={safeCallbackWrap}
      props={{ ...props, nextRenderer }}
      data={props.node.data}
      actionRef={actionRef}
    />
  );
};

export const renderInsight = (nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  const res = (props: ItemDataRenderProps) => <InsightRenderer nextRenderer={nextRenderer} {...props} />;
  return res;
};
