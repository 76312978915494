import { Components, Theme } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiBreadcrumbs'] = {
    styleOverrides: {
      root: {
        display: 'flex',
      },
      ol: {
        display: 'flex',
        width: '100%',
      },
      li: {
        backgroundColor: theme.palette.chip.primary,
        maxHeight: `${theme.spacing(4)}`,
        paddingTop: `2px`,
        paddingBottom: `2px`,
        paddingLeft: `${theme.spacing(1)}`,
        paddingRight: `${theme.spacing(1)}`,
        borderRadius: `${theme.spacing(1)}`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline',
      },
      separator: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  };
  return styleOverrides;
};
