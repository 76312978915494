import * as React from 'react';
import {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridRowProps,
  GridFetchRowsParams,
  GridRow,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';
import { styled } from '@mui/material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { StyledDataGrid } from './StyledDataGrid';
import { ViewItem } from '../ContainerTypes';
import { Box } from '../Box';

LicenseInfo.setLicenseKey(
  'cd38e4d9b7d0412b262f46e0099697aaTz05NjEyMSxFPTE3NTUyNDExOTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

export interface RowProps extends GridRowProps {
  id: string;
  [key: string]: any;
}

export interface VirtualizedTableProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  renderRow: (rowProps: GridRowProps) => React.ReactNode;
  getSize: (items: ViewItem<any>[], index: number) => number;
  rowSelectionModel: GridRowSelectionModel;
  onFetchRows: (params: GridFetchRowsParams) => void; // New prop for lazy loading
  rowsCount: number; // Total number of rows (required for lazy loading)
  apiRef: React.MutableRefObject<GridApiPremium>;
  handleColumnResize: (params: { colDef: GridColDef; width: number }) => void;
}

export const Root = styled(Box)`
  flex: 1 1 auto;
  height: 400px;
`;

export const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  columns,
  rows,
  renderRow,
  rowSelectionModel,
  onFetchRows, // Pass the fetch rows handler
  rowsCount,
  apiRef,
  handleColumnResize,
}) => {
  const CustomRenderRow: React.JSXElementConstructor<GridRowProps> | undefined = React.useCallback(
    props => {
      const row = (props as GridRowProps as RowProps).row;
      if (!row || !row.data) {
        return <GridRow {...props} />;
      }
      return renderRow({
        ...props,
        onClick: () => {},
        onDoubleClick: () => {},
        onContextMenu: () => {},
      });
    },
    [renderRow]
  );
  const getRowSpacing = React.useCallback(params => {
    const gridElement = document.querySelector('.MuiDataGrid-virtualScroller');
    if (gridElement) {
      const { scrollHeight, clientHeight } = gridElement;
      const hasExtraSpace = scrollHeight <= clientHeight;
      return {
        top: params.isFirstVisible ? 0 : 1,
        bottom: params.isLastVisible && !hasExtraSpace ? 15 : 1, // Add space if no extra space
      };
    }
    // Default spacing if the grid element is not found
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 50 : 1,
    };
  }, []);

  return (
    <Root>
      <StyledDataGrid
        sx={{ borderStyle: 'none' }}
        className="drag-and-drop-drilldown"
        rows={rows}
        rowCount={rowsCount} // Pass the total row count
        disableColumnSorting
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        onColumnResize={handleColumnResize}
        rowSelectionModel={rowSelectionModel}
        rowHeight={28}
        apiRef={apiRef}
        autosizeOptions={{ includeHeaders: false, includeOutliers: true }}
        slots={{ row: CustomRenderRow }}
        rowsLoadingMode="server"
        columns={columns}
        hideFooter
        onFetchRows={onFetchRows} // Handle lazy loading
        getRowSpacing={getRowSpacing}
      />
    </Root>
  );
};
