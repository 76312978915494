import React, { useEffect, useMemo } from 'react';
import { KeyModifiers, ShortcutAction } from '~/utilities';
import { CopyLink } from '~/utilities/actions/CopyLink';
import { EmailWithAttachment } from '~/utilities/actions/EmailWithAttachment';
import { EmailWithLink } from '~/utilities/actions/EmailWithLink';
import { RemoveItemAction } from '~/utilities/actions/RemoveItemAction';
import { ShareToTeams } from '~/utilities/actions/ShareToTeams';

export const ShortcurPressedkey = 'SHORTCUT_PRESSED';

const KeyPressPublish: React.FC<React.PropsWithChildren> = ({ children }) => {
  const shortcutActions: ShortcutAction[] = useMemo(
    () => [
      ...RemoveItemAction.create(),
      new EmailWithLink(),
      new CopyLink(),
      new ShareToTeams(),
      new EmailWithAttachment(),
    ],
    []
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      let mod: KeyModifiers = KeyModifiers.None;
      if (event.altKey) mod |= KeyModifiers.Alt;
      if (event.ctrlKey) mod |= KeyModifiers.Ctrl;
      if (event.metaKey) mod |= KeyModifiers.Meta;
      // Check for Ctrl + A and do nothing
      if (mod === KeyModifiers.Ctrl && event.key.toLowerCase() === 'a') {
        event.preventDefault(); // Prevent the default select-all action
        return;
      }
      shortcutActions
        .filter(a => a.shortcut[0] === mod && a.shortcut[1] === event.key)
        .forEach(a => {
          event.preventDefault();
          PubSub.publishSync(ShortcurPressedkey, a);
        });
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [shortcutActions]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default KeyPressPublish;
