import { IndicatorRequestHandler, IndicatorStatus, MenuAction } from '@storybook';
import { IndicatorTexts } from '@vendor/languages';
import { createContext } from 'react';
export interface ProgressData {
  dateKey?: string;
  showDialog: boolean;
  status: IndicatorStatus;
  activeFileIndex: number;
  streamFiles?: string[];
  fileProgress: boolean[];
  currentProgress: number;
  errorMessage?: string; // Add this line
}

export interface IndicatorContextNotifications {
  setProgressData: React.Dispatch<React.SetStateAction<ProgressData>> | null;
  progressRequestHandler?: IndicatorRequestHandler;
  startProgressIndication?: (fileCount: number, indicatorTexts: IndicatorTexts, streamFiles?: string[]) => void;
  oneItemStartIndication?: (activeFileIndex: number) => void;
  oneItemCompletedIndication?: (index: number) => void;
  successfulProcessIndication?: () => void;
  errorfulProcessIndication?: (message: string) => void;
  resetOneItemProgress?: (activeFileIndex: number) => void;
  applyClassName?: (className: string) => void;
  setIndicatorActions?: (actions: MenuAction[]) => void;
}

export const IndicatorContext = createContext<IndicatorContextNotifications>({
  setProgressData: null,
  progressRequestHandler: undefined,
});

export default IndicatorContext;
