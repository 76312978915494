import { Buffer } from 'buffer';
import { IPublicClientApplication } from '@azure/msal-browser';
import { IODataList, IODataWeb } from '@microsoft/sp-odata-types';
import { GraphRequestCallback } from '@microsoft/microsoft-graph-client';
import axios, { AxiosProgressEvent } from 'axios';
import { NullableOption } from '@microsoft/microsoft-graph-types';
import { oDirname } from '@vendor/utils/misc';
import { BaseOdataRequest, CachedOdataRequest, GraphClient, NetworkError, OdataRequest } from '@services';
import { IndicatorRequestHandler } from '@storybook';
import { EmptyListView } from '../sharePointTypes/SPListViewFactory';

class SharePointRequest extends BaseOdataRequest {
  private _headers?: HeadersInit;

  constructor(
    private readonly msal: IPublicClientApplication,
    private readonly rootSite: string,
    request: string
  ) {
    super(request.startsWith(`${rootSite}/`) ? request : rootSite + request);
  }

  override headers(headers: HeadersInit): OdataRequest {
    this._headers = headers;
    return this;
  }

  override async get(_?: GraphRequestCallback | undefined): Promise<any> {
    const token = await this.getToken();
    const resp = await fetch(this.request, {
      headers: {
        ...(this._headers || {}),
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return this.validateError(await resp.json(), resp.status);
  }

  override async getStream(_?: GraphRequestCallback | undefined): Promise<Response> {
    const token = await this.getToken();
    return await fetch(this.request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  override async delete(_?: GraphRequestCallback | undefined): Promise<any> {
    const token = await this.getToken();
    const resp = await fetch(this.request, {
      method: 'POST',
      headers: {
        ...(this._headers || {}),
        Authorization: `Bearer ${token}`,
        'X-HTTP-Method': 'DELETE',
      },
    });
    return resp.status;
  }
  override async patch(content: any, _callback?: GraphRequestCallback): Promise<any> {
    const token = await this.getToken();
    const ct = this._headers?.['Content-Type'] || 'application/json; odata=verbose; charset=utf-8';
    const asIs = ct.indexOf('/json') === -1;
    const body = content === null ? content : asIs ? content.toString() : JSON.stringify(content);
    const resp = await fetch(this.request, {
      method: 'POST',
      headers: {
        ...(this._headers || {}),
        Authorization: `Bearer ${token}`,
        'X-HTTP-Method': 'PATCH',
      },
      body: body,
    });
    return this.validateError(await resp.json(), resp.status);
  }

  override async post(content: any, _?: GraphRequestCallback | undefined): Promise<any> {
    const token = await this.getToken();
    const ct = this._headers?.['Content-Type'] || 'application/json; odata=verbose; charset=utf-8';
    const asIs = ct.indexOf('/json') === -1;
    const body = content === null ? content : asIs ? content.toString() : JSON.stringify(content);
    const resp = await fetch(this.request, {
      method: 'POST',
      headers: {
        ...(this._headers || {}),
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': ct,
      },
      body: body,
    });
    return this.validateError(await resp.json(), resp.status);
  }

  async upload(
    controller: AbortController,
    stream: ArrayBuffer | null,
    start: number,
    totalSize: number,
    progressRequestHandler?: IndicatorRequestHandler,
    fileIndex?: number
  ): Promise<any> {
    const token = await this.getToken();
    const data = await axios.request({
      method: 'post',
      url: this.request,
      data: stream,
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: function (status) {
        return status < 500 || status === 507; // Resolve only if the status code is less than 500
      },
      onUploadProgress: async (p: AxiosProgressEvent) => {
        if (p.total !== undefined && fileIndex !== undefined) {
          progressRequestHandler?.updateProgress(fileIndex, ((start + p.loaded) / totalSize) * 100);
        }
      },
    });
    return this.validateError(data.data, data.status);
  }

  async cancelRequest(
    controller: AbortController,
    largeFile: boolean,
    progressRequestHandler?: IndicatorRequestHandler
  ): Promise<void> {
    const token = await this.getToken();
    if (progressRequestHandler?.needToCancel()) {
      controller.abort();
      if (largeFile) {
        await fetch(this.request, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/json;odata=verbose',
            'content-type': 'application/json;odata=verbose',
            'X-HTTP-Method': 'DELETE',
            'IF-MATCH': '*',
          },
        });
      }
    }
  }

  private async getToken() {
    return (
      await this.msal.acquireTokenSilent({
        scopes: [`${this.rootSite}/AllSites.Manage`],
        account: this.msal.getActiveAccount() || this.msal.getAllAccounts()[0],
        resourceRequestUri: this.rootSite,
      })
    ).accessToken;
  }

  private validateError(res: any, status: number): any {
    const error = res.error || res['odata.error'];
    if (error) throw new NetworkError(error, status);
    return res;
  }
}

export class SharePointClient {
  constructor(
    private readonly gcl: GraphClient,
    readonly rootSite: string
  ) {}
  directapi(request: string) {
    return new SharePointRequest(this.gcl.msal, this.rootSite, request);
  }
  api(request: string) {
    return new CachedOdataRequest(this.directapi(request), this.rootSite, request);
  }
}

export const encodeOdataId = (id: string) => {
  const startPatterns = ["(decodedurl='", "('"];
  startPatterns.forEach(pattern => {
    const decodeStart = id.indexOf(pattern) + pattern.length;
    const decodeEnd = id.lastIndexOf("')");
    if (decodeStart >= pattern.length && decodeEnd > decodeStart)
      id =
        id.substring(0, decodeStart) +
        id.substring(decodeStart, decodeEnd).replaceAll("'", "''").replaceAll('%27', '%27%27') +
        id.substring(decodeEnd);
  });
  return id;
};

export const encodeSpecialCharacters = (specialCharacters: string, withQuote?: boolean) => {
  if (withQuote) specialCharacters = specialCharacters?.replaceAll("'", "''");
  return specialCharacters?.replaceAll('%', '%25').replaceAll('#', '%23').replaceAll('&', '%26');
};

export const getDocumentOdataIdFromId = (siteUrl: string, uniqueId: string) => {
  return encodeOdataId(`${siteUrl}/_api/web/GetFileById('${uniqueId}')`);
};

export const replaceSpecialCharsWithUnderscore = (str: string) => {
  const charsToReplace = ['"', '*', ':', '<', '>', '?', '/', '\\', '|', '\t', '\b', '\n', '\r', '\f'];

  let modifiedString = str;
  for (const char of charsToReplace) {
    modifiedString = modifiedString.split(char).join('_');
  }
  return modifiedString;
};

export const decodeSpecialCharacters = (str: string, withPercent = false) => {
  str = str.replaceAll('%23', '#');
  return withPercent ? str.replaceAll('%25', '%') : str;
};

export const getLibraryOdataIdFromUrl = (libraryUrl: string) => {
  const url = new URL(libraryUrl);
  const siteUrl = oDirname(libraryUrl);
  return encodeOdataId(`${siteUrl}/_api/web/GetList('${url.pathname}')`);
};

export const getLibrarySchema = (libraryUrl: string) => {
  const url = new URL(libraryUrl);
  const siteUrl = oDirname(libraryUrl);
  return encodeOdataId(`${siteUrl}/_api/web/GetList('${url.pathname}')/Fields`);
};

export const getLibraryOdataIdFromListId = (
  siteUrl: NullableOption<string> | undefined,
  listId: NullableOption<string> | undefined
) => {
  return encodeOdataId(`${siteUrl}/_api/web/Lists(guid'${listId}')`);
};

export const getFolderOdataIdFromUrl = (siteUrl: string, folderPath: string) => {
  return encodeOdataId(
    `${siteUrl}/_api/web/GetFolderByServerRelativePath(decodedurl='${encodeSpecialCharacters(folderPath)}')`
  );
};

export const getDocumentOdataIdFromUrl = (siteUrl: string, path: string) => {
  return encodeOdataId(
    `${siteUrl}/_api/web/GetFileByServerRelativePath(decodedurl='${encodeSpecialCharacters(path)}')`
  );
};
export const getSharPointRelativeUrl = (containerWebUrl: string, siteUrl: string) => {
  const suffixSegments = containerWebUrl.split(siteUrl).pop()?.split('/') || [];
  return `${siteUrl}/${suffixSegments[1]}`;
};
export const getRelativeUrlFromView = (viewUrl: string) => {
  return oDirname(oDirname(viewUrl));
};

export const getDriveIdFromUrl = (url: string) => {
  const base64Value = Buffer.from(url).toString('base64');
  const sharingUrl = `u!${base64Value.replace(/=+$/, '').replace('/', '_').replace('+', '-')}`;
  return `https://graph.microsoft.com/v1.0/shares/${sharingUrl}/driveItem`;
};

export interface SPCOdataId {
  'odata.id': string;
}
export const SPCOdataIdKeys: Array<keyof SPCOdataId> = ['odata.id'];

export interface SPCOdataIdTitle extends SPCOdataId {
  Title: string;
}
export const SPCOdataIdTitleKeys: Array<keyof SPCOdataIdTitle> = ['Title', ...SPCOdataIdKeys];

export interface SPCOdataIdName extends SPCOdataId {
  Name: string;
}
export const SPCOdataIdNameKeys: Array<keyof SPCOdataIdName> = ['Name', ...SPCOdataIdKeys];

export interface SPCUser {
  title: string;
  email: string;
}

export interface SPCBaseItem {
  File_x0020_Size(File_x0020_Size: any): number | undefined;
  ID: number;
  ContentTypeId: string;
  FSObjType?: string;
  Title: string;
  UniqueId: string;
  FileRef: string;
  Modified: Date;
  FileLeafRef: string;
  Author: SPCUser;
  Editor: SPCUser;
  [key: string]: any; // Index signature for additional dynamic properties
}

export interface SPCFolder extends SPCBaseItem {
  ProgId: string;
}

export const SPCIdTtitleUrlKeys: Array<keyof IODataWeb> = ['Id', 'Title', 'Url'];
export const SPCSiteKeys: Array<keyof IODataWeb> = [...SPCIdTtitleUrlKeys, 'Description'];
export const DEFAULT_FIELDS = [
  'ContentTypeId',
  'ID',
  'Title',
  'UniqueId',
  'FileRef',
  'Modified',
  'FileLeafRef',
  'Author',
  'Editor',
  'ProgId',
];
export const DEFAULT_VIEW = new EmptyListView()
  .combineWithFields(DEFAULT_FIELDS)
  .combineWithOrderBy(EmptyListView.OrderByName);
export interface SPCList extends IODataList {
  DefaultViewUrl: string; // Missing in ms SP types.
}
export const SPCListPropsKeys = ['Hidden', 'EntityTypeName', 'DefaultViewUrl', 'Id', ...SPCOdataIdTitleKeys];
