import { ViewItem, FeedbackMedium } from '@storybook';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';
declare global {
  interface Window {
    hj: (...args: any[]) => void;
    _hjSettings: { hjid: number; hjsv: number };
  }
}
export const loadHotjar = (): void => {
  if (typeof window.hj === 'undefined') {
    // Dynamically load the Hotjar script
    (function (h: any, o: Document, t: string, j: string) {
      h.hj =
        h.hj ||
        function (...args: any[]) {
          (h.hj.q = h.hj.q || []).push(args);
        };
      h._hjSettings = { hjid: 3519972, hjsv: 6 };

      const head: HTMLHeadElement = o.getElementsByTagName('head')[0]; // Explicitly typed as HTMLHeadElement
      const script: HTMLScriptElement = o.createElement('script'); // Explicitly typed as HTMLScriptElement
      script.async = true; // Set async attribute
      script.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; // Construct the src URL
      head.appendChild(script); // Append the script to the head
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
};
const useFeedbackComponent: ActionRendererHookType = () => {
  return {
    title: strings.lang.allLocatiosToolbar.feedback,
    icon: FeedbackMedium,
    onClick: loadHotjar, // Attach the Hotjar loader to the click handler
  };
};
export class Feedback extends BaseReadActionClass {
  readonly trackedName = 'Feedback';
  readonly useRenderer = useFeedbackComponent;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
