import React, { useCallback } from 'react';
import { ContainerHandlers, OrderByInfo, ViewItem } from '@storybook';
import { VirtualList } from '@storybook';
import { getViewItemRenderer } from '~/components/Tree/Renderers/GetNodeRenderer';
import { generateVirtualListKey } from '~/components/Tree/Renderers/DrilldownHandler';
import { EmptyStateItem, ItemContainer, ItemData } from '~/utilities';
import { SeparatorItem } from '~/utilities/itemTypes/SeparatorItem';

interface VirtualListManagerrProps {
  location: ItemContainer;
  listHandlers: ContainerHandlers<ItemData>;
  selection: Map<string | number, ViewItem<ItemData>>;
  searchProps: any;
  newItems: ItemData[];
  items: ItemData[];
  mappedItems: {
    data: ItemData | SeparatorItem;
    id: string;
  }[];
  nextToken: string | undefined;
  loadingRef: React.MutableRefObject<boolean>;
  counter: React.MutableRefObject<number>;
  fetchPage: (token: any, inp: ItemData[], isCanceled?: () => boolean) => Promise<true | undefined>;
  orderBy: OrderByInfo[];
}

const VirtualListManager: React.FC<VirtualListManagerrProps> = ({
  location,
  listHandlers,
  selection,
  searchProps,
  newItems,
  items,
  mappedItems,
  nextToken,
  loadingRef,
  counter,
  fetchPage,
  orderBy,
}) => {
  const render = useCallback(
    (node: ViewItem<ItemData>) => {
      node.data.isVirtualItem = true;
      node.data.itemColorable = true;
      node.data.isAdvancedSearchItem = location.isAdvancedSearchContainer;
      return getViewItemRenderer(node.data.type)?.({
        node,
        handlers: listHandlers,
        className: selection.get(node.id) ? 'ht_selected' : undefined,
        onSelect: () => undefined,
        onToggle: () => undefined,
        highlightString:
          searchProps?.searchTerm?.length || 0 >= 3 ? searchProps?.searchTerm?.replace(/^"(.*)"$/, '$1') : undefined,
      });
    },
    [listHandlers, location.isAdvancedSearchContainer, searchProps?.searchTerm, selection]
  );

  const size = useCallback(
    (items: ViewItem<ItemData>[], index: number) =>
      newItems.length && newItems.length > index ? 32 : items[index]?.data?.size || 32,
    [newItems]
  );
  return (
    // Get OrderBy from the view
    <VirtualList
      key={generateVirtualListKey(location, counter.current, items[0] instanceof EmptyStateItem, orderBy, searchProps)}
      size={size}
      items={mappedItems}
      render={render}
      hasNextPage={!!nextToken}
      isNextPageLoading={loadingRef.current}
      loadNextPage={() => fetchPage(nextToken, items)}
      withScrollToTop={listHandlers.getSelectedNodes().length < 2}
    />
  );
};

export default VirtualListManager;
