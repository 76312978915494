import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useRef } from 'react';
import ItemNodeBreadcrumbs from '../NavigationItem/components/ItemNodeBreadcrumbs';
import { Typography } from '../Typography';
export interface BreadcrumbsProps {
  itemBreadcrumbs: string | undefined | React.ReactNode;
  color: string;
  highlightString?: string;
}

export default function Breadcrumb(props: { breadcrumbsProps: BreadcrumbsProps }) {
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const isAttachmentBreadcrumbs =
    props.breadcrumbsProps.itemBreadcrumbs !== undefined && typeof props.breadcrumbsProps.itemBreadcrumbs !== 'string';
  return (
    <ItemNodeBreadcrumbs ref={breadcrumbsRef} isAttachmentBreadcrumbs={isAttachmentBreadcrumbs}>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          color: props.breadcrumbsProps.color,
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
        }}
        className={isAttachmentBreadcrumbs ? 'attachments-breadcrumbs' : ''}
      >
        {isAttachmentBreadcrumbs ? (
          <span>{props.breadcrumbsProps.itemBreadcrumbs}</span>
        ) : (
          <Typography
            sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', marginRight: '2px' }}
            variant="H3Regular"
            noWrap
          >
            {props.breadcrumbsProps.itemBreadcrumbs}
          </Typography>
        )}
      </Breadcrumbs>
    </ItemNodeBreadcrumbs>
  );
}
