/* eslint-disable no-console */
import { Buffer } from 'buffer';
import { Team as GTeam } from '@microsoft/microsoft-graph-types';
import { EmailAdaptiveCard, SimpleAdaptiveCard } from '../adaptiveCard';
import { GraphClient, GraphPerson, GraphWebUrlKeys } from './GraphClient';
import {
  UrlParameters,
  getDescriptionFromChat,
  getGroupChatName,
  getInitialName,
  nameMatchesFilter,
} from '../utils/utils';
import { EternalWindow } from './CachedOdataRequest';
export interface EmailStrings {
  msgBody: string;
  from: string;
  to: string;
  shareEmail: string;
}
export interface ChannelGroupShare {
  objectId: string;
}

export interface ChatGroupShare {
  email: string;
}

export interface memberDetails {
  email: string;
  displayName: string;
  userId: string;
}

export interface ChannelGroup {
  id: string;
  name: string;
  teamName: string;
  avatar: Blob | undefined;
  teamId?: string;
  description?: string;
}

export interface ChatGroup {
  id: string;
  name: string;
  members: memberDetails[];
  type: string;
  avatar: string;
  webUrl?: string;
  description?: string;
}

export interface Person {
  id: string;
  name: string;
  avatar: Blob | string;
  members: memberDetails[];
  description?: string;
}
interface SearchResults {
  channelGroups: ChannelGroup[];
  chatGroups: ChatGroup[];
  persons: Person[];
}

export const createChat = async (gcl: GraphClient, userId: string, memberUserId: string) => {
  return await gcl.api(`/chats`).post({
    chatType: 'oneOnOne',
    members: [
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${memberUserId}')`,
      },
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${userId}')`,
      },
    ],
  });
};

export const postMessageOnChannel = async (gcl: GraphClient, ChannelId: string, TeamId: string, adaptiveCard: any) => {
  try {
    const res = await gcl.api(`/teams/${TeamId}/channels/${ChannelId}/messages`).post(JSON.stringify(adaptiveCard));
    return res.webUrl;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postMessageOnChat = async (gcl: GraphClient, chatId: string, adaptiveCard: any) => {
  try {
    return await gcl.api(`/chats/${chatId}/messages`).post(JSON.stringify(adaptiveCard));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const findChat = async (gcl: GraphClient, memberUserId: string, userId: string) => {
  try {
    const chat = await gcl
      .api(`/chats`)
      .expand('members')
      .filter(
        `members/any(o: o/microsoft.graph.aadUserConversationMember/userId eq '${memberUserId}') and chatType eq 'oneOnOne'`
      )
      .get();
    if (chat.value.length !== 0) {
      return chat.value[0];
    } else {
      const chat = await createChat(gcl, userId, memberUserId);
      return chat;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const shareLinkWithGroupChat = async (
  gcl: GraphClient,
  recipients: ChatGroupShare[] | ChannelGroupShare[],
  shareLink: string
) => {
  const base64Value = Buffer.from(shareLink).toString('base64');
  const sharingUrl = `u!${base64Value.replace(/=+$/, '').replace('/', '_').replace('+', '-')}`;

  await gcl.api(`/shares/${sharingUrl}/permission/grant`).post({
    recipients: recipients,
    roles: ['read'],
  });
};

export const PrepareMessage = async (
  target: ChannelGroup | ChatGroup | Person,
  strings: EmailStrings,
  params: UrlParameters,
  msgBody: string,
  needToNotifyState: boolean
) => {
  return !params.isEmail
    ? SimpleAdaptiveCard(
        params.shareUrl,
        params.itemName,
        target.name,
        target.id,
        msgBody,
        params.itemGuid,
        needToNotifyState ? Math.floor(Math.random() * 101).toString() : undefined
      )
    : EmailAdaptiveCard(
        params.shareUrl,
        params.emailTo || '',
        params.emailFrom || '',
        msgBody,
        target.name,
        target.id,
        strings,
        params.itemName,
        params.emailDate || '',
        params.emailBodySnippet || '',
        params.itemTitle,
        params.itemGuid,
        needToNotifyState ? Math.floor(Math.random() * 101).toString() : undefined
      );
};

export const postMessage = async (
  userId: string,
  target: ChannelGroup | ChatGroup | Person,
  strings: any, // TODO: Send the right interface to show strings
  gcl: GraphClient,
  params: UrlParameters,
  msgBody: string,
  needToNotifyState: boolean,
  isDargAndDropFlow: boolean
) => {
  const messageContent = await PrepareMessage(target, strings, params, msgBody, needToNotifyState);
  let webUrl = '';
  let recipients: ChatGroupShare[] | ChannelGroupShare[] = [];
  try {
    if ('teamId' in target) {
      const teamId = (target as ChannelGroup).teamId;
      webUrl = teamId && (await postMessageOnChannel(gcl, (target as ChannelGroup).id, teamId, messageContent));
      if (params.channelId !== (target as ChannelGroup).id && teamId)
        recipients = [{ objectId: teamId } as ChannelGroupShare];
    } else {
      const chat = 'type' in target ? target : await findChat(gcl, target.id, userId);
      webUrl = 'type' in target ? chat.webUrl || ' ' : chat.webUrl;
      await postMessageOnChat(gcl, chat.id, messageContent);
      recipients = ((target as ChatGroup) || (target as Person)).members.map(
        member => ({ email: member.email }) as ChatGroupShare
      );
    }
    try {
      !isDargAndDropFlow && (await shareLinkWithGroupChat(gcl, recipients, params.shareUrl));
    } catch (e) {
      console.error(e);
    }
    return webUrl;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getChannelData = async (teamId: string, channelId: string, gcl: GraphClient) => {
  try {
    return await gcl.api(`/teams/${teamId}/channels/${channelId}`).select(GraphWebUrlKeys).get();
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getChannelAvatar = async (gcl: GraphClient, teamId: string) => {
  return await gcl.api(`/teams/${teamId}/photo/$value`).get();
};

export const getInitialChannel = (params?: UrlParameters): ChannelGroup | null => {
  if (!params || !params.channelId || !params.channelName || !params.teamId) return null;
  return {
    id: params.channelId || '',
    name: params.channelName || '',
    avatar: 'isLoading',
    teamName: '',
    teamId: params.teamId,
    description: '',
  } as unknown as ChannelGroup;
};

export const loadChannelData = async (gcl: GraphClient, params: UrlParameters): Promise<ChannelGroup> => {
  const team: GTeam = await gcl.api(`/teams/${params.teamId}`).get();
  const photo = await getChannelAvatar(gcl, params.teamId || '');
  return {
    id: params.channelId || '',
    name: params.channelName || '',
    avatar: photo,
    teamName: team.displayName || '',
    teamId: params.teamId,
    description: team.displayName || '',
  } as ChannelGroup;
};

export interface ChannelProps {
  type: 'standard' | 'private' | 'shared';
  displayName: string;
  teamId: string;
  id: string;
  teamName: string;
}

export const getTeamChannels = async (gcl: GraphClient): Promise<ChannelProps[]> => {
  try {
    const channelsList = await gcl.api(`/me/drive/root:/Apps/harmon.ie/Share-To-Teams/AllChannelsList.json`).get();
    const channelsFile = await gcl.directApi(`${channelsList['@microsoft.graph.downloadUrl']}`).get();
    return channelsFile?.channels as ChannelProps[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const searchTeamChannels = async (gcl: GraphClient, searchTerm: string): Promise<ChannelGroup[]> => {
  const channelsResults = await getTeamChannels(gcl);
  const matchNames = nameMatchesFilter(searchTerm);
  const channels = channelsResults
    .filter(({ type }) => type === 'standard')
    .filter(
      ({ displayName, teamName }) =>
        matchNames(displayName) || (displayName === 'General' && matchNames(teamName || ''))
    )
    .slice(0, 8);
  const channelsDetails = channels.map(
    async (channel: any) =>
      ({
        id: channel.id,
        name: channel.displayName,
        teamId: channel.teamId,
        teamName: channel.teamName,
        avatar: await getChannelAvatar(gcl, channel.teamId),
        description: channel.teamName,
      }) as ChannelGroup
  );
  const finalChannelsList = await Promise.all(channelsDetails);
  return finalChannelsList.slice(0, 8) as ChannelGroup[];
};

export const getUserIdAndName = async (gcl: GraphClient) => {
  try {
    const user = await gcl.api(`/me`).get();
    return { userId: user.id, userName: user.displayName, userEmail: user.mail };
  } catch (e) {
    console.error(e);
  }
};

export const getUserImageOrInitialName = async (gcl: GraphClient, userid?: string, userName?: string) => {
  try {
    const name = await gcl.api(`/users/${userid}/photo/$value`).get();
    return name || getInitialName(userName);
  } catch (e) {
    console.error(e);
    return getInitialName(userName);
  }
};

export const searchChatsAndMembers = async (gcl: GraphClient, searchTerm: string): Promise<ChatGroup[]> => {
  const chats = await gcl
    .api(`/me/chats`)
    .expand('members')
    .filter(
      `(members/any(o: startsWith(tolower(o/microsoft.graph.aadUserConversationMember/displayName),'${searchTerm.toLowerCase()}') and chatType ne 'oneOnOne')) or Contains(tolower(topic),'${searchTerm.toLowerCase()}')`
    )
    .get();
  const finalChats = chats.value.slice(0, 8).map((chat: any) => ({
    name: chat.topic || getGroupChatName(chat.members, gcl.msal.getActiveAccount()?.name || ''),
    id: chat.id,
    members: chat.members,
    type: chat.chatType,
    avatar: getInitialName(chat.topic),
    webUrl: chat.webUrl,
    description: getDescriptionFromChat(chat),
  }));
  return finalChats as ChatGroup[];
};

export const searchPeople = async (gcl: GraphClient, searchTerm: string, userId: string): Promise<Person[]> => {
  const peopleList = await gcl
    .api(`/me/people?$search="${searchTerm}"`)
    .select(GraphPerson)
    .filter(`personType/subclass eq 'OrganizationUser' or personType/subclass eq 'Guest'`)
    .top(8)
    .get();
  const finalPeopleList = peopleList.value
    .filter((user: any) => user.id !== userId)
    .map(async (person: any) => {
      return {
        id: person.id,
        name: person.displayName,
        avatar: await getUserImageOrInitialName(gcl, person.id, person.displayName),
        members: [
          { email: person.scoredEmailAddresses[0].address, displayName: person.displayName, userId: person.id },
        ],
        description: person.jobTitle,
      } as Person;
    });

  const finalPeople = await Promise.all(finalPeopleList);
  return finalPeople.slice(0, 8) as Person[];
};

export const search = async (gcl: GraphClient, searchValue: string, userId: string): Promise<SearchResults> => {
  const results = await Promise.allSettled([
    searchTeamChannels(gcl, searchValue),
    searchChatsAndMembers(gcl, searchValue),
    searchPeople(gcl, searchValue, userId),
  ]);
  const channelGroups = results[0].status === 'fulfilled' ? results[0].value : [];
  const chatGroups = results[1].status === 'fulfilled' ? results[1].value : [];
  const persons = results[2].status === 'fulfilled' ? results[2].value : [];
  return { channelGroups, chatGroups, persons };
};

export const getWebUrl = async (shareTarget: any, gcl: GraphClient) => {
  if (shareTarget === null) return undefined;
  if ((shareTarget as ChatGroup).webUrl !== undefined) {
    return (shareTarget as ChatGroup).webUrl;
  } else if ((shareTarget as ChannelGroup).teamId !== undefined) {
    const channelWebUrl = await getChannelData(
      (shareTarget as ChannelGroup).teamId || '',
      (shareTarget as ChannelGroup).id || '',
      gcl
    );
    return channelWebUrl.webUrl;
  }
  return undefined;
};

export const getUserEmail = async (gcl: GraphClient) => {
  if (Office.context.mailbox.userProfile) {
    return Office.context.mailbox.userProfile.emailAddress;
  } else {
    try {
      const user = await gcl.api('/me').select('mail').get();
      return user.mail;
    } catch (e) {
      console.error(e);
    }
    return '';
  }
};

export const getListId = async (gcl: GraphClient, siteId: string, listName: string): Promise<string> => {
  try {
    const list = await gcl
      .api(`/sites/${siteId}/lists?$filter=displayName eq '${listName}'`)
      .cache(EternalWindow)
      .get();
    return list.value[0].id;
  } catch (e) {
    console.error(e);
  }
  return '';
};
