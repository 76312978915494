import { ShareSmall, ShareMedium, ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { BaseMenuActionClass } from './BaseMenuAction';
import { InsightItem } from '../graphTypes';

export class ShareAction extends BaseMenuActionClass {
  readonly title = strings.lang.actionToolTip.share;
  readonly icon = [ShareSmall, ShareMedium];

  isQuickAction(data: ItemData, isTopBarItem: boolean) {
    return isTopBarItem ? undefined : data.type === 'site' ? 'only' : 'both';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.every(n => !(n.data instanceof InsightItem) || !n.data.isEmailAttachment);
  }

  public isGroup = (nodes: ViewItem<ItemData>[]) => nodes.length > 1;
}
