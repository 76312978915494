import { NullableOption } from '@microsoft/microsoft-graph-types';
import { EventHandler, SyntheticEvent, useCallback } from 'react';
import { TreeNode } from 'react-hyper-tree/dist/helpers/node';
import { ViewItem } from '@storybook';
import { ItemData } from '../itemTypes';

export const getLastWeekIso = () => new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();
export const isLater = (a?: NullableOption<string>, b?: NullableOption<string>) => (a && !b) || (a && b && a >= b);

export enum OpenAction {
  DOWNLOAD = 'DOWNLOAD',
  OPEN = 'OPEN',
  IN_APP = 'IN_APP',
  CHOOSE = 'CHOOSE',
}

export enum EmailHeaderMappingChoices {
  TeamsOnly = 'Teams only',
  TeamsAbsSharePoint = 'Teams and SharePoint',
  None = 'None',
}

export function useCombinedHandlers<T extends SyntheticEvent<any>>(
  first: EventHandler<T>,
  next?: EventHandler<T>
): EventHandler<T> {
  return useCallback(
    (ev: T) => {
      first(ev);
      if (ev.isPropagationStopped() || !next) return;
      next(ev);
    },
    [first, next]
  );
}

export const isMacOsNative = () => Office.context.platform === Office.PlatformType.Mac;
export const isMacOsSafari = () => {
  const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return isMacOS && isSafari;
};
export const convertMimeToBlob = (mimeString, mimeType) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(mimeString);
  return new Blob([data], { type: mimeType });
};

export const createFileFromMime = (mimeString, fileName, mimeType) => {
  const blob = convertMimeToBlob(mimeString, mimeType);
  return new File([blob], fileName, { type: mimeType });
};

export const isGrandson = (node: TreeNode) => Boolean(node?.options?.parent?.options?.parent);

export function countParents(obj: ViewItem<ItemData>) {
  if (obj?.['options']?.['parent']) {
    return 1 + countParents(obj?.['options']?.['parent']);
  }
  return 0;
}

export const lightOrDark = (color: any) => {
  if (!color) return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color?.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return false;
  } else {
    return true;
  }
};

export const getUploadParams = (fileName: string, isLargeFile: boolean, guid?: string, override?: boolean): string => {
  if (isLargeFile) {
    if (override !== undefined) {
      if (override) {
        return `/Files/GetByPathOrAddStub(DecodedUrl=@a1)/StartUploadFile(uploadId=@a2)?@a1=${fileName}&@a2=${guid}`;
      } else
        return `/Files/AddStubUsingPath(DecodedUrl=@a1,EnsureUniqueFileName=@a2,overwrite=@a3)/StartUploadFile(uploadId=@a4)?@a1=${fileName}&@a2=true&@a3=${override}&@a4=${guid}`;
    }
    return `/Files/AddStubUsingPath(DecodedUrl=@a1)/StartUploadFile(uploadId=@a2)?@a1=${fileName}&@a2=${guid}`;
  } else {
    if (override !== undefined) {
      if (override) {
        return `/Files/AddUsingPath(DecodedUrl=@a1,AutoCheckoutOnInvalidData=@a2,overwrite=@a3)?@a1=${fileName}&@a2=true&@a3=${override}`;
      } else
        return `/Files/AddUsingPath(DecodedUrl=@a1,EnsureUniqueFileName=@a2,AutoCheckoutOnInvalidData=@a3,overwrite=@a4)?@a1=${fileName}&@a2=true&@a3=true&@a4=${override}`;
    }
    return `/Files/AddUsingPath(DecodedUrl=@a1,AutoCheckoutOnInvalidData=@a2)?@a1=${fileName}&@a2=true`;
  }
};
