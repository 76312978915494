import { getEmailData, readMsgEmailAndGetBlob } from '../actions';
import { Folder, ItemContainer } from '../itemTypes';
import { UploadItemProvider } from './UploadItemProvider';
import { GraphClient } from '../../../../services/src/networkAndCache';

export class EmailUploadProvider extends UploadItemProvider {
  constructor(
    gcl: GraphClient,
    email: File | Office.SelectedItemDetails,
    folder: Folder,
    location: ItemContainer,
    isDragAndDrop: boolean = false
  ) {
    super(gcl, email, folder, location, isDragAndDrop);
  }

  // Implementing the method to get the item name for emails
  getItemName(): string {
    if (this.isDragAndDrop && this.item instanceof File) this.name = this.item.name.replace('.msg', '.eml');
    else this.name = `${(this.item as Office.SelectedItemDetails).subject || 'Untitled'}.eml`;
    return this.name;
  }

  // Implementing the method to get the item blob for emails
  async getItemBlob(): Promise<void> {
    if (this.isDragAndDrop && this.item instanceof File)
      this.uploadItemProps = await readMsgEmailAndGetBlob(this.item, this.gcl);
    else this.uploadItemProps = await getEmailData(this.item as Office.SelectedItemDetails, this.gcl);
    this.name = this.uploadItemProps?.name || this.name;
  }
}
