import { Stack, styled } from '@mui/material';

const TreeNodeActions = styled(Stack)`
  margin-left: auto;
  width: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  &.is-favorite-action {
    width: auto;
    visibility: visible;
    opacity: 1;
    margin-right: 22px;
  }
  &.opened {
    &.is-favorite-action {
      margin-right: unset;
    }
    width: auto;
    opacity: 1;
    visibility: visible;
  }
  .navigationItem.isSelected &,
  .navigationItem:hover & {
    &.is-favorite-action {
      margin-right: unset;
    }
    width: auto;
    opacity: 1;
    visibility: visible;
  }

  .navigationItem.isSelected & {
    .MuiButton-action {
      color: ${({ theme }) => theme.palette.texts.onAccentPrimary};
      &:hover {
        color: ${({ theme }) => theme.palette.texts.onAccentPrimary};
        background-color: ${({ theme }) => theme.palette.buttons.accentSecondary};
      }
    }
  }
`;

export default TreeNodeActions;
