import { ErrorSmall, IconProps } from '@storybook';
import { strings } from '@vendor/languages';
import { BaseItem, BaseItemProps } from './BaseItem';
import { ItemData } from './ItemData';

export class ErrorItem extends BaseItem implements ItemData {
  constructor(props: BaseItemProps) {
    super({ ...props, type: 'error' });
  }

  get isVirtual(): boolean {
    return true;
  }
  override get pathOrDescription(): string {
    return strings.lang.errorMessages.tryAgain;
  }

  override itemColorable: boolean = false;
  override isTransientLocation?: boolean = true;

  override getIcon(_expanded: boolean): IconProps {
    return { icon: ErrorSmall, isColorable: false };
  }
}
