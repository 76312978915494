import React, { ReactElement } from 'react';
import { Button, Typography, ViewItem, BAVariant, SVGComponent, Box } from '@harmon.ie-ce/storybook';
import { styled } from '@mui/system';
import { Chip, useTheme } from '@mui/material';

const RightIcon = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
}));

interface MenuActionButtonProps {
  title: string | ReactElement;
  BaseActionVariant: BAVariant;
  Icon?: SVGComponent | ReactElement;
  node?: ViewItem<any>;
  disabled?: boolean;
  hideAction?: boolean;
  opened?: boolean;
  EndIcon?: ReactElement;
  chipLabel?: string;
}

export const MenuActionButton = ({
  Icon,
  title,
  disabled,
  opened,
  BaseActionVariant,
  EndIcon,
  chipLabel,
}: MenuActionButtonProps) => {
  const theme = useTheme();

  // Render Icon
  const renderIconElement = Icon && (typeof Icon === 'function' ? <Icon /> : Icon);

  // Render Chip
  const renderChip = chipLabel && (
    <Chip
      label={chipLabel}
      className="shortcuts"
      sx={{
        backgroundColor: theme.palette.backgrounds.quaternary,
        borderRadius: theme.spacing(1),
        height: theme.spacing(4),
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end',
        '& .MuiChip-label': {
          color: theme.palette.texts.secondary,
        },
        '&:hover': {
          backgroundColor: theme.palette.backgrounds.quaternary,
        },
      }}
    />
  );

  return (
    <Button
      size="medium"
      variant={BaseActionVariant.variant}
      onDoubleClick={e => e.stopPropagation()}
      opened={opened}
      disabled={disabled}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', flexGrow: 1 }}>
        {renderIconElement && <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>{renderIconElement}</Box>}
        <Typography
          variant="H2Regular"
          sx={{
            cursor: 'default',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexShrink: 1,
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Right Section: End Icon or Chip */}
      {EndIcon && <RightIcon>{EndIcon}</RightIcon>}
      {renderChip}
    </Button>
  );
};
