export { ReactComponent as BabcockLogo } from './Babcock.svg';
export { ReactComponent as BauerLogo } from './bauer.svg';
export { ReactComponent as BearingPointLogo } from './bearingPoint.svg';
export { ReactComponent as BoshLogo } from './Bosh.svg';
export { ReactComponent as CanadaLogo } from './canada.svg';
export { ReactComponent as DaimlerLogo } from './daimler.svg';
export { ReactComponent as DrassLogo } from './drass.svg';
export { ReactComponent as EasaLogo } from './Easa.svg';
export { ReactComponent as EyLogo } from './EY.svg';
export { ReactComponent as MapalLogo } from './mapal.svg';
export { ReactComponent as NextLogo } from './Next.svg';
