import { Stack, styled } from '@mui/material';

const ItemNodeBreadcrumbs = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isAttachmentBreadcrumbs',
})<{ isAttachmentBreadcrumbs?: boolean }>`
  text-overflow: ${({ isAttachmentBreadcrumbs }) => (isAttachmentBreadcrumbs ? 'clip' : 'ellipsis')};
  white-space: ${({ isAttachmentBreadcrumbs }) => (isAttachmentBreadcrumbs ? 'normal' : 'nowrap')};
  overflow: ${({ isAttachmentBreadcrumbs }) => (isAttachmentBreadcrumbs ? 'visible' : 'hidden')};
  flex: ${({ isAttachmentBreadcrumbs }) => (isAttachmentBreadcrumbs ? 1 : '0 1 auto')};
  flex-grow: ${({ isAttachmentBreadcrumbs }) => (isAttachmentBreadcrumbs ? 1 : 0)};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-top: 0 !important;

  .navigationItem.isSelected &,
  .navigationItem:hover & {
    display: none;
  }
`;

export default ItemNodeBreadcrumbs;
