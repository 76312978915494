import React from 'react';
import { TreeNode } from 'react-hyper-tree/dist/helpers/node';
import { RefreshSmall, TreeNodeHandlers, TreeViewNode, ViewItem, RefreshMedium } from '@storybook';
import { keyBy as _keyBy } from 'lodash';
import { EternalWindow, GraphClient, NetworkCacheDB, useGraphClient } from '@services';
import { logError } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { DataNode, extGetChildrenType, useGetChildren } from '../../hooks';
import { ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useRefreshAction: ActionRendererHookType = ({ nodes, handlers, actionVariant, useSafeCallback }) => {
  const gcl = useGraphClient();
  const node = nodes[0] as any as TreeNode;
  const getChildren = useGetChildren();
  const onClick = useSafeCallback(
    async (e: React.MouseEvent<Element, MouseEvent>) => {
      if (!actionVariant.withText) e.stopPropagation();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await RefreshAction.refresh(gcl, node, handlers as unknown as TreeNodeHandlers, getChildren);
    },
    [actionVariant.withText, gcl, node, handlers, getChildren]
  );
  return {
    title: strings.lang.actionToolTip.refresh,
    onClick,
    icon: [RefreshSmall, RefreshMedium],
  };
};

export class RefreshAction extends BaseReadActionClass {
  readonly trackedName = 'RefreshItem';
  static async markRefreshStamp(data: ItemContainer, refreshStamp: number | undefined) {
    return await NetworkCacheDB?.updateStamp(data.apiIdKey, EternalWindow, refreshStamp);
  }

  static async refresh(
    gcl: GraphClient,
    node: TreeNode,
    handlers: TreeNodeHandlers,
    getChildren: extGetChildrenType,
    silently = false,
    refreshStamp: number | undefined = Date.now()
  ) {
    refreshStamp = await this.markRefreshStamp(node.data as ItemContainer, refreshStamp);
    if (node.data.hasFolder) {
      try {
        const folder = await (node.data as ItemContainer).getFolder(gcl);
        await folder.onRefresh(gcl, refreshStamp);
      } catch {} // Ignore errors here.
    }
    if (silently && !node.isOpened()) {
      node.asyncDataLoaded = false;
      return;
    }

    if (!silently) handlers.setLoading(node, true);
    try {
      const newChildren = await getChildren({ node } as any as DataNode, refreshStamp, undefined, false, silently);
      this.updateChildren(node, newChildren, handlers);
    } catch (error) {
      logError(error as Error);
    } finally {
      if (!silently) handlers.setLoading(node, false);
    }
  }

  private static updateChildren(node: TreeViewNode, newChildren: ItemData[], handlers: TreeNodeHandlers) {
    const curChildren = _keyBy(node.getChildren(), 'id');
    handlers.setRawChildren(node, [], undefined, true);
    newChildren.forEach(d => {
      const e = curChildren[d.id];
      if (e === undefined) handlers.setRawChildren(node, [d], 'last', false);
      else {
        handlers.setNodeData(e, d);
        handlers.setChildren(node, [e], 'last', false);
      }
    });
  }

  readonly useRenderer = useRefreshAction;

  isQuickAction(data: ItemData) {
    return data.isRootSection || data.isUserManaged || data.type === 'teamschatfiles' ? 'only' : undefined;
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length === 1 && nodes[0].data.canRefresh && nodes[0]['options'] !== undefined;
  }
}
