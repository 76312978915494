import React, { useCallback, useMemo } from 'react';
import { multiSelectionsEventPublish, TreeHandlers, TreeView, TreeViewProps, WrapperComponentProps } from '@storybook';
import { treeHandlers } from 'react-hyper-tree';
import { useGraphClient } from '@services';
import { useGetChildren } from '~/hooks';
import {
  ItemData,
  FavoritesRoot,
  OneDriveRoot,
  SharedWithMeRoot,
  RecentRoot,
  TeamsRoot,
  SharePointSitesRoot,
  ItemListChangesType,
  ItemsListChangedEvent,
  isItemContainer,
  resolveAndPublishItem,
} from '~/utilities';
import KeyPressSubscribe from '~/modules/KeyPress/KeyPressSubscribe';
import { useSubscribe } from '~/hooks/useSubscribe';
import { RenderNodeProps, getTreeNodeRenderer } from './Renderers/GetNodeRenderer';
import { updateNewItemsCount } from './Renderers/ExpandHandler';

export const getHarmonieTree = () => {
  return treeHandlers.trees['HarmonieTree'];
};

export const getHarmonieTreeHandlers = (): TreeHandlers => {
  return getHarmonieTree().handlers as any as TreeHandlers;
};

const WrapperComponent: React.FC<WrapperComponentProps> = ({ children, handlers }) => {
  const gcl = useGraphClient();
  useSubscribe(
    ItemsListChangedEvent,
    useCallback(
      ({ toResolve, updated, added, deleted, location }: ItemListChangesType) => {
        const node = location && handlers.getNode(location.id);
        if (!node) return;

        updated = { ...updated, ...toResolve };

        let updatedChildren: ItemData[] = node.getChildren().map(n => n.data as ItemData);

        if (toResolve) {
          // Resolving can only be done when the current location has folders.
          if (location.hasFolder) Object.values(toResolve).forEach(item => resolveAndPublishItem(gcl, location, item));
        }

        if (added) {
          updateNewItemsCount(node, Object.keys(added).length);
          const addedItems = Object.values(added);

          addedItems.forEach(newItem => {
            newItem.rootPrefix = location.rootPrefix;
            if (isItemContainer(newItem.type)) newItem.getChildren = location.getChildren;

            const existingIndex = updatedChildren.findIndex(v => v.apiIdKey === newItem.apiIdKey);

            if (existingIndex !== -1) {
              // Replace existing item and move it to the top
              updatedChildren.splice(existingIndex, 1); // Remove existing item
              updatedChildren.unshift(newItem); // Add new item to the top
            } else {
              // Add new item
              const where = (newItem.isDocument && updatedChildren.findIndex(v => v.isDocument)) || 0;
              updatedChildren.splice(where !== -1 ? where : updatedChildren.length, 0, newItem);
            }
          });
        }

        if (updated) {
          updatedChildren = updatedChildren.map(n => {
            const updatedItem = updated[n.apiIdKey];
            if (updatedItem) {
              updatedItem.rootPrefix = location.rootPrefix;
              updatedItem.getChildren = n.getChildren;
              return updatedItem;
            }
            return n;
          });
        }

        if (deleted) {
          updatedChildren = updatedChildren.filter(n => !deleted[n.apiIdKey]);
          multiSelectionsEventPublish({ handlers: handlers as any, selectedItem: [] });
        }

        handlers.setRawChildren(node, updatedChildren, undefined, true);
      },
      [gcl, handlers]
    )
  );

  return (
    <div>
      <KeyPressSubscribe handlers={handlers as any} />
      {children}
    </div>
  );
};

const Tree = () => {
  const getChildren = useGetChildren();
  const data = useMemo(
    () =>
      [
        FavoritesRoot.instance,
        RecentRoot.instance,
        new SharedWithMeRoot(),
        TeamsRoot.instance,
        SharePointSitesRoot.instance,
        new OneDriveRoot(),
      ].map((n: ItemData) => {
        n.getChildren = getChildren;
        return n;
      }),
    [getChildren]
  );
  const renderNode = (props: RenderNodeProps) =>
    // eslint-disable-next-line react/prop-types
    getTreeNodeRenderer((props.node.data as ItemData).type)?.(props);

  const treeViewProps: TreeViewProps = useMemo(
    () => ({
      useTreeStateProps: {
        data,
        id: 'HarmonieTree',
        multipleSelect: true,
      },
      treeProps: {
        renderNode,
        disableLines: true,
      },
      wrapperComponent: WrapperComponent,
      rootIds: data.map(d => d.id as string),
      canBeSelected: n => (n.data as ItemData).supportsSelection,
    }),
    [data]
  );

  return <TreeView {...treeViewProps} wrapperComponent={WrapperComponent} />;
};

export default Tree;
