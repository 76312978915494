import React, { useContext } from 'react';
import {
  LottieAnimation,
  LicenseManagerDarkAnimationData,
  LicenseManagerLightAnimationData,
  useTheme,
  Stack,
  Typography,
  Button,
  RunningLine,
  NextLogo,
  MapalLogo,
  EyLogo,
  DaimlerLogo,
  CanadaLogo,
  BoshLogo,
  BearingPointLogo,
  BauerLogo,
  BabcockLogo,
  DrassLogo,
  EasaLogo,
} from '@storybook';
import Drawer from '@harmon.ie-ce/storybook/src/components/Drawer/Drawer';
import { strings } from '@vendor/languages';
import { LicenseContext } from '~/licenseManager/LicenseContext';

const LicenseManager = () => {
  const theme = useTheme();
  const { isLicenseEnabled } = useContext(LicenseContext);
  const components = [
    <NextLogo key="1" />,
    <MapalLogo key="2" />,
    <EyLogo key="3" />,
    <DaimlerLogo key="4" />,
    <CanadaLogo key="5" />,
    <BoshLogo key="6" />,
    <BearingPointLogo key="7" />,
    <BauerLogo key="8" />,
    <BabcockLogo key="9" />,
    <DrassLogo key="10" />,
    <EasaLogo key="11" />,
  ];

  return (
    <>
      <Drawer open={!isLicenseEnabled} useTransition={true}>
        <Stack
          sx={{
            background: theme.palette.backgrounds.primary,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RunningLine components={components} velocity={5} direction={'ltr'} />
          <Stack mt={4} mb={3} width={'100%'}>
            <LottieAnimation
              options={{
                loop: true,
                autoplay: true,
                animationData:
                  theme.palette.mode === 'dark' ? LicenseManagerDarkAnimationData : LicenseManagerLightAnimationData,
              }}
            />
          </Stack>
          <Typography
            color={theme.palette.texts.primary}
            variant="OnBoardingBoldTitle"
            sx={{ textAlign: 'center', paddingX: 8, paddingBottom: 3 }}
          >
            {strings.lang.license.title}
          </Typography>
          <Typography color={theme.palette.texts.secondary} variant="H1Regular" sx={{ textAlign: 'center' }}>
            {strings.lang.license.description}
          </Typography>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', marginTop: 6, width: '100%' }}>
            <Button variant="primary" onClick={() => window.open('https://harmon.ie/pricing/')} fullWidth>
              {strings.lang.license.buy}
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default LicenseManager;
