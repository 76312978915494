import { ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import React from 'react';
import { useDrilldownNavigation } from '~/components/Tree/Renderers/DrilldownHandler';
import { isItemContainer, ItemContainer, ItemData, MicrosoftApps } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useOpenLocation: ActionRendererHookType = ({ nodes, useCallback }) => {
  const data = nodes[0].data as ItemContainer;
  const onClick = useDrilldownNavigation({ data: data, useCallback });
  const Icon = data.getIcon(false)?.icon;
  const fill = data.isRootSection ? { 'data-nofill': true } : {};
  const newIcon = Icon && <Icon {...fill} />;
  return {
    title: strings.lang.actionToolTip.open,
    icon: newIcon,
    onClick,
  };
};

export class OpenLocation extends BaseReadActionClass {
  readonly trackedName = 'OpenLocation';
  readonly useRenderer = useOpenLocation;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const isOneDriveRoot = nodes[0].data.isRootSection && nodes[0].data.OfficeAppLocated === MicrosoftApps.OneDrive;
    return (nodes.length == 1 && isItemContainer(nodes[0].data.type) && !nodes[0].data.isRootSection) || isOneDriveRoot;
  }
}
