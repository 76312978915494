import { SelectItem } from '@storybook';
import { GraphClient } from '@services';
import { getEmailAttachmentContent } from '../actions';
import { Folder, ItemContainer } from '../itemTypes';
import { UploadItemProvider } from './UploadItemProvider';

export class AttacmentEmailUploadProvider extends UploadItemProvider {
  constructor(gcl: GraphClient, file: SelectItem, folder: Folder, location: ItemContainer) {
    super(gcl, file, folder, location);
  }
  getItemName(): string {
    return (this.item as SelectItem).name;
  }
  async getItemBlob(): Promise<void> {
    const data = await getEmailAttachmentContent(this.item as SelectItem, this.gcl);
    this.uploadItemProps = {
      name: (this.item as SelectItem).name,
      data: data,
    };
  }
}
